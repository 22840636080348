
import { DateTime } from 'luxon'
import { toRefs, PropType, defineComponent } from 'vue'
import { getDiscountPeriodStatus } from '../../../domain/statusByPeriod'
import { Discount } from '../../../domain/Discount'
import CPeriodStatusIcon from '../CPeriodStatusIcon.vue'
import { luxonDateDisplayFormat } from '../../luxon.constants'
import { DiscountRouteName } from '../../routes/discountRouteName'
import { useDiscountDiscountablesCounter } from '../../composables/useDiscountDiscountablesCounter'
import { discountPoliciesById } from '../../../domain/constants/DiscountPolicies'
import { formatDiscountUnitLabel } from '../../../domain/formatDiscountUnitLabel'

export default defineComponent({
  name: 'CDiscountListItem',
  components: { CPeriodStatusIcon },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  data: () => ({
    routeName: DiscountRouteName.DETAIL,
  }),
  setup(props) {
    const { discount } = toRefs(props)

    const { uncompliantDiscountableCount, activeDiscountableCount, discountablesTotalCount } =
      useDiscountDiscountablesCounter(discount)

    return { uncompliantDiscountableCount, activeDiscountableCount, discountablesTotalCount }
  },
  computed: {
    formatedStartDate() {
      return DateTime.fromJSDate(this.discount.validityStartDate).toFormat(luxonDateDisplayFormat)
    },
    formatedEndDate() {
      if (!DateTime.fromJSDate(this.discount.validityEndDate!).isValid) return '-'
      return DateTime.fromJSDate(this.discount.validityEndDate!).toFormat(luxonDateDisplayFormat)
    },
    discountStatus() {
      return getDiscountPeriodStatus(this.discount)
    },
    formatDiscountPolicy() {
      const policy = discountPoliciesById[this.discount.policy.id]
      return (policy && policy.text) || ''
    },
    discountUnit() {
      return formatDiscountUnitLabel(this.discount)
    },
  },
})
