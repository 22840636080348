
import { computed } from 'vue'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '../../composables/useStore'
import { partnerContactService } from '../../services'

const csvFileProperties = {
  name: 'partner-contact.csv',
  full_path: '/assets/csv/partner-contact.csv',
}
export default {
  name: 'CImportPartnerContact',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()

    const csvColumnDetails = [
      {
        header: 'member_code',
        format: 'VARCHAR',
        exampleValue: '1234AZE',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__member-code')}`,
        mandatory: 'Oui',
      },
      {
        header: 'contact_first_name',
        format: 'VARCHAR',
        exampleValue: 'Jean',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-first-name')}`,
        mandatory: 'Oui',
      },
      {
        header: 'contact_last_name',
        format: 'VARCHAR',
        exampleValue: 'Dupont',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-last-name')}`,
        mandatory: 'Oui',
      },
      {
        header: 'contact_phone_number',
        format: 'VARCHAR',
        exampleValue: '0102030405',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-phone-number')}`,
        mandatory: 'Non',
      },
      {
        header: 'contact_email',
        format: 'VARCHAR',
        exampleValue: 'jean.dupont@email.example',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-email')}`,
        mandatory: 'Oui',
      },
      {
        header: 'contact_roles',
        format: 'LIST',
        exampleValue: 'ACHETEUR|AGRICULTEUR',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-functions')}`,
        mandatory: 'Non',
      },
      {
        header: 'contact_can_valid_orders',
        format: 'VARCHAR',
        exampleValue: `TRUE, FALSE, <VIDE>`,
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-can-validate-orders')}`,
        mandatory: 'Non',
      },
      {
        header: 'contact_certiphyto_end_date',
        format: 'DATE',
        exampleValue: '2020-08-15',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-certiphyto-end-date')}`,
        mandatory: 'Non',
      },
      {
        header: 'contact_explosive_precursor_end_date',
        format: 'DATE',
        exampleValue: '2023-01-01',
        description: `${i18n.t('ctx.import.partner-contact__csv-column-details__contact-explosive-precursor-end-date')}`,
        mandatory: 'Non',
      },
    ]

    const descriptionLines = [
      `${i18n.t('ctx.import.common__description-line-1')}`,
      `${i18n.t('ctx.import.common__description-line-2')}`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.common__description-line-4')}`,
    ]
    const partnerContactImportInformation = computed(() => {
      return store.getters.partnerContactImportInformation
    })
    const partnerId = computed(() => {
      return store.getters.cooperativeId
    })
    const partner = computed(() => {
      return store.getters.cooperative
    })

    const importInformation = computed(() => {
      return {
        count: partnerContactImportInformation.value?.customerContactsCount,
        lastImportAt: partnerContactImportInformation.value?.lastCustomerContactsImportAt,
      }
    })

    function afterPartnerContactImport() {
      store.dispatch('setPartnerContactImportInformation')
    }

    function downloadCSV() {
      return partnerContactService.downloadPartnerContact(partnerId.value, partner.value.name)
    }

    return {
      afterPartnerContactImport,
      csvColumnDetails,
      csvProperties: csvFileProperties,
      descriptionLines,
      downloadCSV,
      importInformation,
      partner,
      partnerContactService,
      partnerId,
      partnerContactImportInformation,
    }
  },
}
