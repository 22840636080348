<template>
  <div class="import-technical-sales" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :importFileService="technicalSaleService"
      :csvProperties="csvProperties"
      :pageTitle="`${$t('ctx.import.sales__title')}`"
      :uploadButtonLabel="`${$t('ctx.import.sales__description')}`"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
      :importInformation="importInformation"
      @afterLoading="afterTechnicalSalesImport"
    >
      <c-import-information
        :title="`${$t('ctx.import.sales__informations')}`"
        :nbResultText="`${$t('ctx.import.sales__count')} :`"
        :exportText="`${$t('ctx.import.sales__export-action')}`"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      >
      </c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { provideUploadCondition, CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { cooperativeService, technicalSaleService } from '../../services'

const csvFileProperties = {
  name: 'technicians.csv',
  full_path: '/assets/csv/technicians.csv',
}
export default {
  name: 'CImportTechnicalSales',
  setup() {
    const i18n = use18n()
    const { setUploadCondition } = provideUploadCondition(i18n)
    return { setUploadCondition }
  },
  computed: {
    ...mapGetters(['technicalSalesImportInformation', 'cooperativeId', 'cooperative']),
    importInformation() {
      const { technicalSalesImportInformation: { technicalSalesCount, lastTechnicalSalesImportAt } = {} } = this
      return { count: technicalSalesCount, lastImportAt: lastTechnicalSalesImportAt }
    },
  },
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    afterTechnicalSalesImport() {
      this.$store.dispatch('setTechnicalSalesImportInformation')
    },
    downloadCSV() {
      return cooperativeService.downloadTechnicalSales(this.cooperativeId, this.cooperative.name)
    },
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      technicalSaleService,
      csvColumnDetails: [
        {
          header: 'tc_uid',
          format: 'VARCHAR',
          exampleValue: '1234AZE',
          description: `Identifiant unique ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_member_code_list',
          format: 'LIST',
          exampleValue: '111|222|333',
          description: `Liste des identifiants des ${i18n.t('message.memberships')} rattaché au ${i18n.t(
            'message.technician',
          )}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_first_name',
          format: 'VARCHAR',
          exampleValue: 'Jean',
          description: `Prénom ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_last_name',
          format: 'VARCHAR',
          exampleValue: 'Dupont',
          description: `Nom de famille ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_mobile_phone_number',
          format: 'VARCHAR',
          exampleValue: '0102030405',
          description: `Numéro de téléphone ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_email',
          format: 'VARCHAR',
          exampleValue: 'jean.dupont@email.example',
          description: `Email ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'tc_role',
          format: 'VARCHAR',
          exampleValue: "Conseiller d'exploitation",
          description: `Rôle ${i18n.t('message.ofTechnician')} au sein de ${i18n.t('message.thePartner')}`,
          mandatory: 'Non',
        },
        {
          header: 'tc_expertise_domain',
          format: 'VARCHAR',
          exampleValue: 'Grande culture',
          description: `Domaine d'expertise ${i18n.t('message.ofTechnician')}.`,
          mandatory: 'Non',
        },
      ],
      descriptionLines: [
        i18n.t('ctx.import.sales__advise-1'),
        i18n.t('ctx.import.sales__advise-2'),
        i18n.t('ctx.import.sales__advise-3'),
        i18n.t('ctx.import.sales__advise-4'),
      ],
    }
  },
  beforeMount() {
    this.setUploadCondition('ctx.import.sales__separation-law')
  },
}
</script>
