import { extend } from 'vee-validate-last'
import {
  required,
  required_if as requiredIf,
  min_value as minValue,
  integer,
  max,
  regex,
} from 'vee-validate-last/dist/rules'
import { DateTime } from 'luxon'

const installCustomValidateRules = () => {
  // afterOrToday
  extend('fromToday', {
    message: `Le champ {_field_} doit être postérieur à la date d'aujourd'hui`,
    validate: (value) => {
      const todayDate = new Date().toISOString().split('T')[0]
      return new Date(value) >= new Date(todayDate)
    },
  })

  extend('after', {
    params: ['targetValue'],
    validate: (value, { targetValue }) => {
      return !DateTime.fromJSDate(new Date(targetValue)).isValid || new Date(value) >= new Date(targetValue)
    },
    message: `Le champ {_field_} doit être postérieur à la {targetValue}`,
  })

  extend('decimal', {
    message: (field, { length }) => `Le champ ${field} doit être un nombre et peut contenir ${length} décimales`,
    validate: (value, { length }) => {
      return !value.toString().split('.')[1] || value.toString().split('.')[1].length <= length
    },
    params: ['length'],
  })

  extend('sliceMinRule', {
    validate: (value, { sliceMin }) => {
      if (parseInt(sliceMin, 10) === 0) return value >= 0
      return value > parseInt(sliceMin, 10)
    },
    paramNames: ['sliceMin'],
  })

  extend('sliceMaxRule', {
    validate: (value, { sliceMin }) => {
      if (sliceMin === 'null') return value >= 0
      return value > parseInt(sliceMin, 10)
    },
    paramNames: ['sliceMin'],
  })

  extend('greaterThan', {
    params: [{ name: 'targetValue', isTarget: true }],
    validate: (value, { targetValue }) => {
      return !value || parseInt(value, 10) >= parseInt(targetValue, 10)
    },
  })

  extend('lessThan', {
    params: [{ name: 'targetValue', isTarget: true }],
    validate: (value, { targetValue }) => {
      return !targetValue || parseInt(value, 10) <= parseInt(targetValue, 10)
    },
  })

  extend('strictlygreaterThan', {
    params: [{ name: 'targetValue', isTarget: true }],
    validate: (value, { targetValue }) => {
      return !targetValue || parseFloat(value) > parseFloat(targetValue)
    },
  })

  extend('strictlylessThan', {
    params: [{ name: 'targetValue', isTarget: true }],
    validate: (value, { targetValue }) => {
      return !targetValue || parseFloat(value) < parseFloat(targetValue)
    },
  })

  extend('required', required)

  extend('required_if', requiredIf)

  extend('regex', {
    ...regex,
    params: [...regex.params, 'literalAuthorizedChar'],
    message: `Le champ {_field_} ne doit contenir que {literalAuthorizedChar}`,
  })

  extend('min_value', minValue)

  extend('integer', integer)

  extend('max', max)

  extend('multipleOf', {
    params: [{ name: 'targetValue', isTarget: true }],
    validate: (value, { targetValue }) => {
      return value % targetValue === 0
    },
    message: `Le champ {_field_} doit être un multiple de {targetValue}`,
  })
}
export default installCustomValidateRules
