
import { computed, toRefs, PropType, defineComponent } from 'vue'
import { formattedMeasureUnits } from '../../domain/formattedMeasureUnits'
import CStepSubGroup from './CStepSubGroup.vue'
import { Discount, isDiscountWithScale } from '../../domain/Discount'
import CDiscountAmountDetail from './CDiscountAmountDetail.vue'
import CDiscountUsesDetails from './CDiscountUsesDetails.vue'
import CDiscountScaleDetail from './CDiscountScaleDetail.vue'
import CDiscountScope from './CDiscountScope.vue'
import CPromoCodeDetail from './CPromoCodeDetail.vue'

export default defineComponent({
  name: 'CDiscountApplicationDetail',
  components: {
    CStepSubGroup,
    CDiscountScaleDetail,
    CDiscountAmountDetail,
    CDiscountScope,
    CPromoCodeDetail,
    CDiscountUsesDetails,
  },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  setup(props) {
    const { discount } = toRefs(props)
    return {
      isDiscountWithScale: computed(() => isDiscountWithScale(discount.value)),
      formatedMeasureUnit: computed(
        () =>
          formattedMeasureUnits[discount.value.measureUnit] && formattedMeasureUnits[discount.value.measureUnit].single,
      ),
    }
  },
})
