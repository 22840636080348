import { DateTime } from 'luxon'
import { ImportReportDTO } from '@invivodf/common/src/contexts/discount/infra/datasource/ImportReportDTO'
import { use18n } from '@invivodf/locales/src/use18n'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

function byUpdatedDate(firstReport, secondReport) {
  return DateTime.fromISO(secondReport.imported_at).diff(DateTime.fromISO(firstReport.imported_at)).milliseconds
}

export const translateTypeFields = (typeField) => {
  if (typeField === 'memberships') {
    return use18n().t('message.memberships')
  }
  if (typeField === 'technical-sales') {
    return use18n().t('message.technicians')
  }
  if (typeField === 'variant') {
    return 'catalogue'
  }
  if (typeField === 'offer') {
    return 'offres'
  }
  if (typeField === 'address') {
    return 'adresses'
  }
  if (typeField === 'member-segmentation') {
    return 'segmentation'
  }
  if (typeField === 'stocks') {
    return 'stocks'
  }
  if (typeField === 'quota') {
    return 'quotas'
  }
  if (typeField === 'discount-association') {
    return 'associations remise'
  }
  if (typeField === 'customer-contacts') {
    return 'contacts'
  }

  return typeField
}

export const normalizeReport = (report) => ({
  ...report,
  imported_at: report.updated_at || report.created_at,
  translated_type: translateTypeFields(report.type),
})

export interface HttpClient {
  get<T>(path: string): Promise<{ status: number; data: T }>
}

export interface ImportReportFetcher {
  fetchOne(cooperativeId: number, id: number): string
  fetchAll: (cooperativeId: number) => string
  type?: string
}

export default class ImportReportsService {
  private readonly httpClient: HttpClient

  private fetchers: ImportReportFetcher[]

  constructor(
    private readonly cooperativeApiHost,
    private readonly productApiHost,
    private readonly orderApiHost,
    private readonly discountApiHost,
  ) {
    // @ts-ignore EXPLIQUEZ POURQUOI VOUS IGNOREZ CETTE ERREUR
    this.httpClient = createAxiosClientWithAladinBearer()
    this.fetchers = [
      this.getProductCooperativeVariantReports(),
      this.getAllPartnerReports(),
      this.getOffersReports(),
      this.getStockReports(),
      this.getQuotaReports(),
      this.getDiscountAssociationsReports(),
    ]
  }

  private getDiscountAssociationsReports() {
    return {
      fetchAll: (cooperativeId: number) =>
        `${this.discountApiHost}/v2/discount-associations/import-reports?seller_id=${cooperativeId}`,
      fetchOne: (cooperativeId: number, importReportId: number) =>
        `${this.discountApiHost}/v2/discount-associations/import-reports/${importReportId}?seller_id=${cooperativeId}`,
      type: 'discount-association',
    }
  }

  private getQuotaReports() {
    return {
      fetchAll: (cooperativeId: number) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/quotas/import-reports`,
      fetchOne: (cooperativeId: number, importReportId: number) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/quotas/import-reports/${importReportId}`,
      type: 'quota',
    }
  }

  private getStockReports() {
    return {
      fetchAll: (cooperativeId: number) =>
        `${this.orderApiHost}/v1/cooperatives/${cooperativeId}/stocks/import-reports`,
      fetchOne: (cooperativeId: number, importReportId: number) =>
        `${this.orderApiHost}/v1/cooperatives/${cooperativeId}/stocks/import-reports/${importReportId}`,
      type: 'stocks',
    }
  }

  private getOffersReports() {
    return {
      fetchAll: (cooperativeId: number) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/offers/import-reports`,
      fetchOne: (cooperativeId: number, importReportId: number) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/offers/import-reports/${importReportId}`,
      type: 'offer',
    }
  }

  private getProductCooperativeVariantReports() {
    return {
      fetchAll: (cooperativeId: number) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/cooperative-variants/import-reports`,
      fetchOne: (cooperativeId: number, importReportId) =>
        `${this.productApiHost}/v1/cooperatives/${cooperativeId}/cooperative-variants/import-reports/${importReportId}`,
      type: 'variant',
    }
  }

  private getAllPartnerReports() {
    return {
      fetchAll: (cooperativeId: number) => `${this.cooperativeApiHost}/v1/partners/${cooperativeId}/import-reports`,
      fetchOne: (cooperativeId: number, importReportId: number) =>
        `${this.cooperativeApiHost}/v1/partners/${cooperativeId}/import-reports/${importReportId}`,
    }
  }

  async listAll(cooperativeId: number) {
    const importReports = await Promise.all(
      this.fetchers.map(async (fetcher) => this.fetchAndIgnoreError(fetcher.fetchAll(cooperativeId))),
    )
    return importReports.flat().map(normalizeReport).sort(byUpdatedDate)
  }

  async fetchAndIgnoreError(baseUrl) {
    try {
      const { status, data } = await this.httpClient.get<ImportReportDTO[]>(baseUrl)
      return status === 204 ? [] : data
    } catch (error) {
      return []
    }
  }

  async get(cooperativeId, importType, importReportId) {
    const fetcher = this.fetchers.find((item) => item.type === importType)

    if (fetcher) {
      const response = await this.httpClient.get<ImportReportDTO>(fetcher.fetchOne(cooperativeId, importReportId))
      return normalizeReport(response.data)
    }

    const response = await this.httpClient.get<ImportReportDTO>(
      `${this.cooperativeApiHost}/v1/partners/${cooperativeId}/import-reports/${importReportId}`,
    )
    return normalizeReport(response.data)
  }

  async getLastProductImport(cooperativeId, importType) {
    try {
      const baseUrl = `${this.productApiHost}/v1/cooperatives/${cooperativeId}/${importType}/import-reports/latest`
      const response = await this.httpClient.get(baseUrl)
      return response.data
    } catch (error) {
      return Promise.resolve({ data: {} })
    }
  }

  async getLastZoneChargesImportReport(cooperativeId) {
    try {
      const baseUrl = `${this.productApiHost}/v1/cooperatives/${cooperativeId}/zone-charges/import-reports/latest`
      const response = await this.httpClient.get(baseUrl)
      return response.data
    } catch (error) {
      return Promise.resolve({ data: {} })
    }
  }
}
