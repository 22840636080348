<template>
  <div class="offer-list__container">
    <div v-if="offers.length === 0" class="text-center" data-test="no-offer-block">
      <img src="../../assets/no_result.svg" />
      <p class="text-center" data-cy="noOfferMessage">{{ $t('ctx.offers.list.noResult') }}</p>
    </div>
    <c-offer-list-item v-else v-for="offer in offers" :key="offer.id" :offer="offer" @editOffer="editOffer" />
    <b-modal hide-header hide-footer ref="offerFormModal" dialog-class="offer__right-modal" size="lg">
      <c-variant-offer-form
        :variantId="editingOffer.variantInvivoId"
        :measureQuantity="editingOffer.measureQuantity"
        :measureUnit="editingOffer.measureUnit"
        :isSoldAsUnit="editingOffer.isSoldAsUnit"
        :offer="editingOffer"
        :productKind="editingOffer.productKind"
        storeNamespace="offers"
        @cancel="$refs.offerFormModal.hide()"
        @success="$refs.offerFormModal.hide()"
        @fail="$refs.offerFormModal.hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import COfferListItem from './COfferListItem.vue'
import CVariantOfferForm from '../variant/CVariantOfferForm/CVariantOfferForm.vue'

const editingOffer = {
  cooperativeVariant: {
    variant: {
      product: {},
    },
  },
}

export default {
  name: 'COfferList',
  props: ['offers'],
  components: { CVariantOfferForm, COfferListItem },
  data() {
    return {
      editingOffer,
    }
  },
  methods: {
    editOffer(id) {
      this.editingOffer = this.offers.find((offer) => offer.id === id)
      this.$refs.offerFormModal.show()
    },
  },
}
</script>

<style lang="scss">
.offer-list__container {
  flex-grow: 1;
}
.offer-list__container .offer-list-item {
  border-top: 0;
  box-shadow: unset;
}
.modal-dialog.offer__right-modal {
  position: absolute;
  right: 0;
  margin: 0;
  width: 50%;
}
</style>
