import { use18n } from '@invivodf/locales/src/use18n'
import { isSameDay } from '../date/date.filter'

const toHours = (timeInMillis) => timeInMillis / 1000 / 3600

const toMinutes = (timeInMillis) => timeInMillis / 1000 / 60

const getHoursAgo = (date, now) => toHours(now.getTime() - date.getTime())

const getMinutesAgo = (date, now) => toMinutes(now.getTime() - date.getTime())

const isLessThanAnHour = (hoursAgo) => hoursAgo < 1

const isLessThanAMinute = (minsAgo) => minsAgo < 1

export const timeAgo = (date, now) => {
  const i18n = use18n()
  const hoursAgo = getHoursAgo(date, now)
  if (isLessThanAnHour(hoursAgo)) {
    const minsAgo = getMinutesAgo(date, now)
    if (isLessThanAMinute(minsAgo)) {
      return i18n.t('dates.time.now')
    }

    return `${i18n.t('dates.time.ago')} ${Math.floor(minsAgo)}m`
  }
  return `${i18n.t('dates.time.ago')} ${Math.floor(hoursAgo)}h`
}

export default function time(value, { now = new Date() } = {}) {
  const i18n = use18n()
  if (!value) {
    return ''
  }
  const date = new Date(value)
  if (isSameDay(date, now)) {
    return timeAgo(date, now)
  }
  const localeTime = date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  return `${i18n.t('dates.time.at')} ${localeTime}`
}
