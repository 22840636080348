<template>
  <div>
    <c-import-base
      :importFileService="segmentService"
      :csvProperties="csvProperties"
      :pageTitle="`${$t('ctx.import.segmentation__title')}`"
      :uploadButtonLabel="`${$t('ctx.import.segmentation__description')}`"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        :title="`${$t('ctx.import.segmentation__informations')}`"
        :nbResultText="`${$t('ctx.import.segmentation__count')} : `"
        :exportText="`${$t('ctx.import.segmentation__export-action')}`"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { segmentService } from '../../services'

const csvFileProperties = {
  name: 'segments.csv',
  full_path: '/assets/csv/segments.csv',
}
export default {
  name: 'CImportSegments',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      segmentService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
      csvColumnDetails: [
        {
          header: 'segment_code',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>premium</code>',
          description:
            "<p>Le <code>segment_code</code> est un identifiant unique correspondant à un segment dans votre système d'information.</p><p>La référence peut comprendre tous les caratères unicode à l'exception du point-virgule et de la virgule.</p>",
          mandatory: 'Oui',
        },
        {
          header: 'member_code',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>1234AZE</code>',
          description: `<p>${i18n.t('ctx.user.member-code')}</p>`,
          mandatory: 'Oui',
        },
      ],
      descriptionLines: [
        i18n.t('ctx.import.segmentation__advise-1').toString(),
        i18n.t('ctx.import.segmentation__advise-2').toString(),
        i18n.t('ctx.import.segmentation__advise-3').toString(),
        i18n.t('ctx.import.segmentation__advise-4').toString(),
      ],
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return segmentService.download(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
