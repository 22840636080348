/* eslint-disable camelcase */
import { use18n } from '@invivodf/locales/src/use18n'

export interface WordingInterface {
  message: {
    yes: string
    no: string
  }
  editor: {
    title: string
    description: string
    back: string
    steps: {
      CLogisticOfferRouteStep: string
      CLogisticOfferDetailsStep: string
      CLogisticOfferPricingStep: string
      CLogisticOfferExclusionZonesStep: string
      CLogisticOfferSummaryStep: string
    }
    mandatoryFields: string
    logisticRoute: string
    deliveryName: string
    deliveryDelay: string
    preview: string
    offerId: string
    internalName: string
    offerDescription: string
    deliveryDate: string
    validityPeriod: {
      label: string
      start: string
      end: string
      reset: string
    }
    save: string
    skip: string
    reuseDeliveryMode: string
    deliveryMode: {
      route: {
        click_and_collect: string
        home_delivery: string
        pickup_point: string
      }
      delay: {
        hour: string
        day: string
        working_day: string
        week: string
        month: string
      }
    }
    pricing: {
      select: string
      logistic_order: string
      order: string
      advanced: string
      standard: string
      skip: string
      unit: {
        weight: string
        revenue: string
        revenueTTC: string
      }
      add_weight_pricing: string
      add_revenue_pricing: string
      add_rule: string
      types: {
        global: string
        logistic: string
        advanced: string
      }
    }
    exclusionZones: {
      description: string
      label: string
      legend: string
    }
    summary: {
      idOffer: string
      deliveryDate: string
      description: string
    }
    optional: string
    step: string
  }
  list: {
    title: string
    create: string
    headers: {
      internalName: string
      offerId: string
      deliveryMode: string
      nbCommercialOffers: string
      actions: string
    }
    update: string
    delete: string
  }
  modal: {
    delete: {
      header: string
      body: string
    }
    deliveryMode: {
      header: string
      body: string
    }
    confirm: string
    cancel: string
  }
  notification: {
    successSave: string
    successDelete: string
    error: string
  }
  dates: {
    connector: string
  }
}

export function useLogisticOfferWording() {
  const i18n = use18n()
  const wording: WordingInterface = {
    message: {
      yes: i18n.t('message.yes').toString(),
      no: i18n.t('message.no').toString(),
    },
    editor: {
      title: i18n.t('ctx.logistic_offer.editor.title').toString(),
      description: i18n.t('ctx.logistic_offer.editor.description').toString(),
      back: i18n.t('ctx.logistic_offer.editor.back').toString(),
      steps: {
        CLogisticOfferRouteStep: i18n.t('ctx.logistic_offer.editor.steps.CLogisticOfferRouteStep').toString(),
        CLogisticOfferDetailsStep: i18n.t('ctx.logistic_offer.editor.steps.CLogisticOfferDetailsStep').toString(),
        CLogisticOfferPricingStep: i18n.t('ctx.logistic_offer.editor.steps.CLogisticOfferPricingStep').toString(),
        CLogisticOfferExclusionZonesStep: i18n
          .t('ctx.logistic_offer.editor.steps.CLogisticOfferExclusionZonesStep')
          .toString(),
        CLogisticOfferSummaryStep: i18n.t('ctx.logistic_offer.editor.steps.CLogisticOfferSummaryStep').toString(),
      },
      mandatoryFields: i18n.t('ctx.logistic_offer.editor.mandatoryFields').toString(),
      logisticRoute: i18n.t('ctx.logistic_offer.editor.logisticRoute').toString(),
      deliveryName: i18n.t('ctx.logistic_offer.editor.deliveryName').toString(),
      deliveryDelay: i18n.t('ctx.logistic_offer.editor.deliveryDelay').toString(),
      preview: i18n.t('ctx.logistic_offer.editor.preview').toString(),
      offerId: i18n.t('ctx.logistic_offer.editor.offerId').toString(),
      internalName: i18n.t('ctx.logistic_offer.editor.internalName').toString(),
      offerDescription: i18n.t('ctx.logistic_offer.editor.offerDescription').toString(),
      deliveryDate: i18n.t('ctx.logistic_offer.editor.deliveryDate').toString(),
      validityPeriod: {
        label: i18n.t('ctx.logistic_offer.editor.validityPeriod.label').toString(),
        start: i18n.t('ctx.logistic_offer.editor.validityPeriod.start').toString(),
        end: i18n.t('ctx.logistic_offer.editor.validityPeriod.end').toString(),
        reset: i18n.t('ctx.logistic_offer.editor.validityPeriod.reset').toString(),
      },
      save: i18n.t('ctx.logistic_offer.editor.save').toString(),
      skip: i18n.t('ctx.logistic_offer.editor.skip').toString(),
      reuseDeliveryMode: i18n.t('ctx.logistic_offer.editor.reuseDeliveryMode').toString(),
      deliveryMode: {
        route: {
          click_and_collect: i18n.t('ctx.logistic_offer.editor.deliveryMode.route.click_and_collect').toString(),
          home_delivery: i18n.t('ctx.logistic_offer.editor.deliveryMode.route.home_delivery').toString(),
          pickup_point: i18n.t('ctx.logistic_offer.editor.deliveryMode.route.pickup_point').toString(),
        },
        delay: {
          hour: i18n.t('ctx.logistic_offer.editor.deliveryMode.delay.hour').toString(),
          day: i18n.t('ctx.logistic_offer.editor.deliveryMode.delay.day').toString(),
          working_day: i18n.t('ctx.logistic_offer.editor.deliveryMode.delay.working_day').toString(),
          week: i18n.t('ctx.logistic_offer.editor.deliveryMode.delay.week').toString(),
          month: i18n.t('ctx.logistic_offer.editor.deliveryMode.delay.month').toString(),
        },
      },
      pricing: {
        select: i18n.t('ctx.logistic_offer.editor.pricing.select').toString(),
        logistic_order: i18n.t('ctx.logistic_offer.editor.pricing.logistic_order').toString(),
        order: i18n.t('ctx.logistic_offer.editor.pricing.order').toString(),
        advanced: i18n.t('ctx.logistic_offer.editor.pricing.advanced').toString(),
        standard: i18n.t('ctx.logistic_offer.editor.pricing.standard').toString(),
        skip: i18n.t('ctx.logistic_offer.editor.pricing.skip').toString(),
        unit: {
          weight: i18n.t('ctx.logistic_offer.editor.pricing.unit.weight').toString(),
          revenue: i18n.t('ctx.logistic_offer.editor.pricing.unit.revenue').toString(),
          revenueTTC: i18n.t('ctx.logistic_offer.editor.pricing.unit.revenueTTC').toString(),
        },
        add_weight_pricing: i18n.t('ctx.logistic_offer.editor.pricing.add_weight_pricing').toString(),
        add_revenue_pricing: i18n.t('ctx.logistic_offer.editor.pricing.add_revenue_pricing').toString(),
        add_rule: i18n.t('ctx.logistic_offer.editor.pricing.add_rule').toString(),
        types: {
          global: i18n.t('ctx.logistic_offer.editor.pricing.types.global').toString(),
          logistic: i18n.t('ctx.logistic_offer.editor.pricing.types.logistic').toString(),
          advanced: i18n.t('ctx.logistic_offer.editor.pricing.types.advanced').toString(),
        },
      },
      exclusionZones: {
        description: i18n.t('ctx.logistic_offer.editor.exclusionZones.description').toString(),
        label: i18n.t('ctx.logistic_offer.editor.exclusionZones.label').toString(),
        legend: i18n.t('ctx.logistic_offer.editor.exclusionZones.legend').toString(),
      },
      summary: {
        idOffer: i18n.t('ctx.logistic_offer.editor.summary.idOffer').toString(),
        deliveryDate: i18n.t('ctx.logistic_offer.editor.summary.deliveryDate').toString(),
        description: i18n.t('ctx.logistic_offer.editor.summary.description').toString(),
      },
      optional: i18n.t('ctx.logistic_offer.editor.optional').toString(),
      step: i18n.t('ctx.logistic_offer.editor.step').toString(),
    },
    list: {
      title: i18n.t('ctx.logistic_offer.list.title').toString(),
      create: i18n.t('ctx.logistic_offer.list.create').toString(),
      headers: {
        internalName: i18n.t('ctx.logistic_offer.list.headers.internalName').toString(),
        offerId: i18n.t('ctx.logistic_offer.list.headers.offerId').toString(),
        deliveryMode: i18n.t('ctx.logistic_offer.list.headers.deliveryMode').toString(),
        nbCommercialOffers: i18n.t('ctx.logistic_offer.list.headers.nbCommercialOffers').toString(),
        actions: i18n.t('ctx.logistic_offer.list.headers.actions').toString(),
      },
      update: i18n.t('ctx.logistic_offer.list.update').toString(),
      delete: i18n.t('ctx.logistic_offer.list.delete').toString(),
    },
    modal: {
      delete: {
        header: i18n.t('ctx.logistic_offer.modal.delete.header').toString(),
        body: i18n.t('ctx.logistic_offer.modal.delete.body').toString(),
      },
      deliveryMode: {
        header: i18n.t('ctx.logistic_offer.modal.deliveryMode.header').toString(),
        body: i18n.t('ctx.logistic_offer.modal.deliveryMode.body').toString(),
      },
      confirm: i18n.t('ctx.logistic_offer.modal.confirm').toString(),
      cancel: i18n.t('ctx.logistic_offer.modal.cancel').toString(),
    },
    notification: {
      successSave: i18n.t('ctx.logistic_offer.notification.successSave').toString(),
      successDelete: i18n.t('ctx.logistic_offer.notification.successDelete').toString(),
      error: i18n.t('ctx.logistic_offer.notification.error').toString(),
    },
    dates: {
      connector: i18n.t('dates.connector').toString(),
    },
  }

  const variabilizeWording = (sentence: string, ...params: string[]): string => {
    if (!params.length) return sentence
    const placeholders = sentence.match(/%s/)
    if (placeholders) {
      let newSentence = sentence
      placeholders.forEach((placeholder, index) => {
        newSentence = newSentence.replace(placeholder, params[index])
      })
      return newSentence
    }

    if (window.env.VUE_APP_CONTEXT === 'farmi') {
      sentence = sentence.replace(/aladin/gi, 'farmi')
    } else if (window.env.VUE_APP_CONTEXT === 'episens') {
      sentence = sentence.replace(/aladin/gi, 'episens')
    }

    return sentence
  }
  return { wording, variabilizeWording }
}
