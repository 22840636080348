import { render, staticRenderFns } from "./PLogisticOfferList.vue?vue&type=template&id=ed7a01de&scoped=true"
import script from "./PLogisticOfferList.vue?vue&type=script&lang=ts"
export * from "./PLogisticOfferList.vue?vue&type=script&lang=ts"
import style0 from "./PLogisticOfferList.vue?vue&type=style&index=0&id=ed7a01de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed7a01de",
  null
  
)

export default component.exports