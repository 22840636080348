
import { computed, defineComponent, onBeforeMount, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { IdfBreadcrumb } from '@invivodf/ui-kit'
import { useStore } from '@/composables/useStore'
import CProductHeader from './CProductHeader/CProductHeader.vue'
import CProductVariantList from '../../components/CProductVariantList/CProductVariantList.vue'
import CProductReviews from '../../components/CProductReviews/CProductReviews.vue'
import CProductDescription from '../../components/CProductDescription/CProductDescription.vue'
import CProductCharacteristics from '../../components/CProductCharacteristics/CProductCharacteristics.vue'
import CAssociatedProducts from '../../components/CAssociatedProducts/CAssociatedProducts.vue'
import { CProductTab, CProductTabItem } from './CProductTab'
import { buildBreadcrumbs } from './breadcrumb.utils'
import CUpdateProductImages from './CUpdateProductImages/CUpdateProductImages.vue'
import { provideProductImage } from './useProductImage'

export default defineComponent({
  name: 'VProduct',
  components: {
    CProductDescription,
    CProductHeader,
    CProductVariantList,
    CProductReviews,
    CProductCharacteristics,
    CAssociatedProducts,
    CProductTab,
    CProductTabItem,
    CUpdateProductImages,
    IdfBreadcrumb,
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    onBeforeMount(() => {
      store.dispatch('catalog/fetchProductAndOffers', route.params.productId)
      // fetchCategories used to display categories for edition
      store.dispatch('fetchCategories')
    })

    const product = computed(() => store.getters['catalog/getProductById'](route.params.productId))
    provideProductImage(product)

    function getCategories() {
      return store.getters.categories
    }

    function hasAssociatedVariants() {
      return product.value?.variants.some((variant) => variant.associated)
    }

    function isAnExclusiveProduct() {
      return product.value?.exclusive
    }

    function getProductCatalogRoute() {
      if (isAnExclusiveProduct()) return 'search-cooperative-referential'
      if (hasAssociatedVariants()) return 'search-cooperative'
      return 'search-national'
    }

    const breadcrumbsItems = ref<any[]>([])
    watch(product, (value) => {
      if (!value) {
        return
      }
      breadcrumbsItems.value = buildBreadcrumbs(
        { name: value.name, _id: value.id },
        value.categories[0],
        getProductCatalogRoute(),
      )
    })

    return reactive({
      product,
      cooperativeId: computed(() => store.getters.cooperativeId),
      categories: computed(getCategories),
      isProductReady: computed(() => product.value && (getCategories() ?? []).length > 0),
      hasAssociatedVariants: computed(hasAssociatedVariants),
      breadcrumbsItems,
      show: {
        description: computed(isAnExclusiveProduct),
        characteristics: computed(isAnExclusiveProduct),
        reviews: computed(hasAssociatedVariants),
        associatedProducts: computed(hasAssociatedVariants),
        images: computed(isAnExclusiveProduct),
      },
      onSaveDescription(description) {
        store.dispatch('saveProductDescription', {
          productId: product.value?.id,
          description,
        })
      },
      onSaveCharacteristics(characteristics) {
        store.dispatch('saveProductCharacteristics', {
          productId: product.value?.id,
          characteristics,
        })
      },
    })
  },
})
