<template>
  <div class="container">
    <h4>{{ $t('ctx.offers.variantOfferForm.quantityInformation.title') }}</h4>
    <table>
      <tr data-test="q1">
        <th>{{ $t('ctx.offers.variantOfferForm.quantityInformation.min') }}</th>
        <td>{{ min }}</td>
        <td>{{ displayQuantity(min) }}{{ displayUnit }}</td>
      </tr>
      <tr v-if="steps > 2" data-test="q2">
        <th></th>
        <td>{{ 1 * step + min }}</td>
        <td>{{ displayQuantity(step + min) }}{{ displayUnit }}</td>
      </tr>
      <tr v-if="steps > 3" data-test="q3">
        <th></th>
        <td>{{ 2 * step + min }}</td>
        <td>{{ displayQuantity(2 * step + min) }}{{ displayUnit }}</td>
      </tr>
      <tr>
        <th></th>
        <td>
          <svg width="2" viewBox="0 0 2 12" fill="#141414">
            <circle cx="1" cy="1" r="1" />
            <circle cx="1" cy="6" r="1" />
            <circle cx="1" cy="11" r="1" />
          </svg>
        </td>
        <td>
          <svg width="2" viewBox="0 0 2 12" fill="#141414">
            <circle cx="1" cy="1" r="1" />
            <circle cx="1" cy="6" r="1" />
            <circle cx="1" cy="11" r="1" />
          </svg>
        </td>
      </tr>
      <tr data-test="qMax">
        <th>{{ $t('ctx.offers.variantOfferForm.quantityInformation.max') }}</th>
        <td>{{ realMax }}</td>
        <td>{{ realMaxQuantity }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
const UNICODE_INFINITY = '\u221E'
export default {
  name: 'PQuantityInformation',
  props: {
    min: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    max: Number,
    unit: String,
    measureQuantity: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    realMax() {
      if (!this.max || this.max === Infinity || this.max < this.min) return UNICODE_INFINITY
      return this.min + Math.floor((this.max - this.min) / this.step) * this.step
    },
    steps() {
      if (this.realMax === UNICODE_INFINITY) return Infinity
      return Math.floor((this.realMax - this.min) / this.step) + 1
    },
    displayUnit() {
      return this.unit ? `\u00A0${this.unit}` : ''
    },
    realMaxQuantity() {
      const q = this.displayQuantity(this.realMax)
      return Number.isNaN(q) ? UNICODE_INFINITY : `${q}${this.displayUnit}`
    },
  },
  methods: {
    displayQuantity(q) {
      return Math.round(q * this.measureQuantity * 1e5) / 1e5
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #ebfaff;
  padding: 20px;
  width: 186px;
  text-align: center;
}
h4 {
  font-size: 16px;
  font-weight: bold;
}
table {
  width: 100%;
  border-collapse: collapse;
}
td,
th {
  width: 30%;
  border: 1px solid #536975;
  border-top: none;
  border-bottom: none;
}
th {
  border-left: none;
}
td:last-of-type {
  border-right: none;
}
</style>
