<template>
  <c-import-base
    :csvProperties="csvProperties"
    :importFileService="quotaService"
    :descriptionLines="descriptionLines"
    :csvColumnDetails="csvColumnDetails"
    :pageTitle="`${$t('ctx.import.quotas__title')}`"
    :uploadButtonLabel="`${$t('ctx.import.quotas__description')}`"
    :reportWarning="`${$t('ctx.import.quotas__report-warning')}`"
  >
    <c-import-information
      :title="`${$t('ctx.import.quotas__informations')}`"
      :nbResultText="`${$t('ctx.import.quotas__count')}`"
      :exportText="`${$t('ctx.import.quotas__export-action')}`"
      :importInformation="importInformation"
      :downloadCSV="downloadCSV"
    />
  </c-import-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { quotaService } from '../../services'

const csvFileProperties = {
  name: 'quotas.csv',
  full_path: '/assets/csv/quotas.csv',
}
export default {
  name: 'CImportQuotas',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      quotaService,
      descriptionLines: [
        i18n.t('ctx.import.quotas__advise-1').toString(),
        i18n.t('ctx.import.quotas__advise-2').toString(),
        i18n.t('ctx.import.quotas__advise-3').toString(),
        i18n.t('ctx.import.quotas__advise-4').toString(),
      ],
      csvColumnDetails: [
        {
          header: 'quota_reference',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>QUOTA_REF1</code>',
          description: '<p>Référence unique du quota (Reference Quota).</p>',
          mandatory: 'Oui',
        },
        {
          header: 'mesh_id',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>6:INVXCKLET7V7CT16PHY:REF_OFR</code>',
          description: '<p>ID de l’objet métier (offre dans un premier temps) sur lequel le quota s’applique.</p>',
          mandatory: 'Oui',
        },
        {
          header: 'value',
          format: 'DECIMAL',
          exampleValue: 'exemple : <code>5</code>',
          description: `<p>Valeur du quota en unité de vente.</p>`,
          mandatory: 'Oui',
        },
        {
          header: 'tech_id',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>TC0001</code>',
          description: `<p>Identifiant du ${i18n.t(
            'message.technician',
          )} (tc_uid) sur lequel le quota s’applique, à renseigner pour un quota à la maille ${i18n.t(
            'message.technician',
          )}(fonctionnalité à venir).</p>`,
          mandatory: 'Non',
        },
      ],
      importInformation: {
        count: null,
        lastImportAt: null,
      },
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return quotaService.downloadQuotas(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
