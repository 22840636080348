import fileService from '@invivodf/common/src/contexts/parameters/file.service'
import CloudinaryService from './cloudinary/cloudinary.service'
import UserService from './user.service'
import ProductService from './product.service'
import CategoryService from './category.service'
import OfferService from './offer.service'
import QuotaService from './quota/quota.service'
import StockMovementService from './stock-movement.service'
import StockService from './stock.service'
import ThresholdsStockService from './thresholdsStock/thresholds-stock.service'
import CooperativeCustomProductsService from './cooperative-custom-products.service'
import CooperativeCustomProductsV2Service from './cooperative-custom-products-v2.service'
import CooperativeVariantService from './cooperative-variant.service'
import CooperativeService from './cooperative.service'
import OrderService from './order.service'
import ShippingRatesService from './shipping-rates.service'
import ImportService from './import.service'
import MembershipService from './membership.service'
import TechnicalSaleService from './technical-sale.service'
import StoreService from './store.service'
import ShippingAddressesService from './shipping-addresses.service'
import ImportReportsService from './import-reports.service'
import ZoneService from './zone.service'
import PaymentDueDateService from './payment-due-date/payment-due-date.service'
import SegmentService from './segment.service'
import ZoneChargesService from './zone-charges.service'
import StripeDashboardRedirect from './stripe-dashboard-redirect.service'
import UsagesService from './usages.service'
import PartnerContactService from './partner-contact.service'

window.env = window.env || {}

const userApiHost = window.env.USER_API_HOST
const productApiHost = window.env.PRODUCT_API_HOST
const quotaApiHost = window.env.QUOTA_API_HOST
const orderApiHost = window.env.ORDER_API_HOST
const membershipApiHost = window.env.MEMBERSHIP_API_HOST
const storeApiHost = window.env.STORE_API_HOST
const articleApiHost = window.env.ARTICLE_API_HOST
const discountApiHost = window.env.DISCOUNT_API_HOST
const zoneChargesApiHost = window.env.ZONE_CHARGES_API_HOST
const zoneApiHost = window.env.ZONE_API_HOST
const paymentDueDateApiHost = window.env.PAYMENT_DUE_DATE_API_HOST
const cloudinaryTokenApiHost = window.env.CLOUDINARY_TOKEN_API_HOST
const cloudinaryBucketName = window.env.CLOUDINARY_BUCKET_NAME
const usagesApiHost = window.env.USAGES_API_HOST

const cooperativeService = new CooperativeService({ userApiHost })
const userService = new UserService({ userApiHost })
const productService = new ProductService({ productApiHost })
const categoryService = new CategoryService({ productApiHost })
const offerService = new OfferService({ productApiHost })
const quotaService = new QuotaService({ quotaApiHost })
const stockMovementService = new StockMovementService({ productApiHost })
const stockService = new StockService({ orderApiHost })
const cooperativeVariantService = new CooperativeVariantService({ productApiHost })
const orderService = new OrderService({ orderApiHost })
const shippingRatesService = new ShippingRatesService({ orderApiHost })
const importService = new ImportService()
const membershipService = new MembershipService(membershipApiHost)
const technicalSaleService = new TechnicalSaleService(membershipApiHost)
const storeService = new StoreService(storeApiHost)
const shippingAddressesService = new ShippingAddressesService(membershipApiHost)
const importReportsService = new ImportReportsService(membershipApiHost, productApiHost, orderApiHost, discountApiHost)
const zoneService = new ZoneService({ zoneApiHost })
const segmentService = new SegmentService({ userApiHost })
const zoneChargesService = new ZoneChargesService({ zoneChargesApiHost })
const cooperativeCustomProductsService = new CooperativeCustomProductsService({ productApiHost })
const cooperativeCustomProductsV2Service = new CooperativeCustomProductsV2Service({ productApiHost })
const stripeDashboardRedirect = new StripeDashboardRedirect({ orderApiHost })
const paymentDueDateService = new PaymentDueDateService({ paymentDueDateApiHost })
const cloudinaryService = new CloudinaryService(cloudinaryTokenApiHost, cloudinaryBucketName)
const thresholdsStockService = new ThresholdsStockService({ orderApiHost })
const usagesService = new UsagesService({ usagesApiHost })
const partnerContactService = new PartnerContactService(membershipApiHost)

export default usagesService

export {
  productService,
  categoryService,
  cooperativeService,
  userService,
  offerService,
  quotaService,
  stockMovementService,
  stockService,
  cooperativeVariantService,
  orderService,
  shippingRatesService,
  importService,
  membershipService,
  technicalSaleService,
  segmentService,
  storeService,
  shippingAddressesService,
  fileService,
  importReportsService,
  zoneService,
  zoneChargesService,
  cooperativeCustomProductsService,
  cooperativeCustomProductsV2Service,
  paymentDueDateService,
  stripeDashboardRedirect,
  cloudinaryService,
  thresholdsStockService,
  usagesService,
  partnerContactService,
}
