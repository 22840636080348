<template>
  <div class="import-stores" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :importFileService="storeService"
      :csvProperties="csvProperties"
      :pageTitle="pageTitle"
      :uploadButtonLabel="uploadButtonLabel"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        :title="title"
        :nbResultText="nbResultText"
        :exportText="exportText"
        :importInformation="importInformation"
        :downloadCSV="downloadStoresCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'
import { storeService } from '../../services'
import ImportService from '../../services/import.service'

const csvFileProperties = {
  name: 'stores.csv',
  full_path: '/assets/csv/stores.csv',
}

export default defineComponent({
  name: 'CImportStores',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()

    const pageTitle = computed(() => i18n.t('ctx.import.stores__page-title').toString())
    const uploadButtonLabel = computed(() => i18n.t('ctx.import.stores__upload-button-label').toString())
    const title = computed(() => i18n.t('ctx.import.stores__information-title').toString())
    const nbResultText = computed(() => i18n.t('ctx.import.stores__information-result-number').toString())
    const exportText = computed(() => i18n.t('ctx.import.stores__information-export-text').toString())

    const csvProperties = ref(csvFileProperties)

    const csvColumnDetails = computed(() => [
      {
        header: 'store_name',
        description: i18n.t('ctx.import.stores__csv-column-details__store-name-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'Rassot S.A',
      },
      {
        header: 'store_code',
        description: i18n.t('ctx.import.stores__csv-column-details__store-code-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: '1234AZE',
      },
      {
        header: 'store_schedule',
        description: i18n.t('ctx.import.stores__csv-column-details__store-schedule-description').toString(),
        format: 'BLOB TEXT',
        mandatory: i18n.t('message.no').toString(),
        exampleValue: i18n.t('ctx.import.stores__csv-column-details__store-schedule-example').toString(),
      },
      {
        header: 'store_address_line1',
        description: i18n.t('ctx.import.stores__csv-column-details__address-line1-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'Chemin des Esses',
      },
      {
        header: 'store_address_line2',
        description: i18n.t('ctx.import.stores__csv-column-details__address-line2-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.no').toString(),
      },
      {
        header: 'store_address_line3',
        description: i18n.t('ctx.import.stores__csv-column-details__address-line3-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.no').toString(),
      },
      {
        header: 'store_postal_code',
        description: ' ',
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: '69000',
      },
      {
        header: 'store_city',
        description: ' ',
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'Adoncourt',
      },
      {
        header: 'store_region',
        description: i18n.t('ctx.import.stores__csv-column-details__region-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'CODE_SUD',
      },
      {
        header: 'store_gps_location_lat',
        description: i18n.t('ctx.import.stores__csv-column-details__gps-lat-description').toString(),
        format: 'FLOAT',
        mandatory: i18n.t('message.no').toString(),
        exampleValue: '43.34342',
      },
      {
        header: 'store_gps_location_long',
        description: i18n.t('ctx.import.stores__csv-column-details__gps-long-description').toString(),
        format: 'FLOAT',
        mandatory: i18n.t('message.no').toString(),
        exampleValue: '-23.42657',
      },
      {
        header: 'store_contact_phone',
        description: ' ',
        format: 'VARCHAR',
        mandatory: i18n.t('message.no').toString(),
        exampleValue: '123456787',
      },
      {
        header: 'store_accept_crop_protection',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-crop-protection-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'TRUE',
      },
      {
        header: 'store_accept_seeds',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-seeds-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'TRUE',
      },
      {
        header: 'store_accept_fertilizer_bigbag',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-fertilizer-bigbag-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'FALSE',
      },
      {
        header: 'store_accept_fertilizer_tank',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-fertilizer-tank-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'FALSE',
      },
      {
        header: 'store_accept_fertilizer_bulk',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-fertilizer-bulk-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'FALSE',
      },
      {
        header: 'store_accept_fertilizer_other_packaging',
        description: i18n.t('ctx.import.stores__csv-column-details__accept-fertilizer-other-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'FALSE',
      },
      {
        header: 'store_is_pickup_point',
        description: i18n.t('ctx.import.stores__csv-column-details__is-pickup-point-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'TRUE/FALSE',
      },
      {
        header: 'store_is_click_and_collect',
        description: i18n.t('ctx.import.stores__csv-column-details__is-click-and-collect-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'TRUE/FALSE',
      },
      {
        header: 'store_is_departure_point',
        description: i18n.t('ctx.import.stores__csv-column-details__is-departure-point-description').toString(),
        format: 'BOOLEAN',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'TRUE/FALSE',
      },
    ])

    const descriptionLines = computed(() => [
      `${i18n.t('ctx.import.common__description-line-1')}<b>.csv</b>`,
      `${i18n.t('ctx.import.common__description-line-2')}<code>;</code>.`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.common__description-line-4')}<b>UTF8</b>.`,
    ])

    const importInformation = computed(() => {
      const storesImportInformation = store.getters.storesImportInformation || {}
      return {
        count: storesImportInformation.storesCount,
        lastImportAt: storesImportInformation.lastStoresImportAt,
      }
    })

    const cooperative = computed(() => store.getters.cooperative)

    const afterStoresImport = () => {
      store.dispatch('setStoresImportInformation')
    }

    const downloadStoresCSV = async () => {
      const storesCSV = await storeService.downloadStores(cooperative.value.id)
      const url = window.URL.createObjectURL(new Blob([storesCSV]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', ImportService.createFileName(cooperative.value.name, 'stores'))
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    }

    return {
      csvProperties,
      storeService,
      csvColumnDetails,
      descriptionLines,
      importInformation,
      pageTitle,
      uploadButtonLabel,
      title,
      nbResultText,
      exportText,
      afterStoresImport,
      downloadStoresCSV,
      cooperative,
    }
  },
})
</script>
