
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { ImportReport } from '../../domain/ImportReport'
import PImportReportDetail from './PImportReportDetail/PImportReportDetail.vue'
import PHeader from '../../../../application/presenters/PHeader.vue'

export default defineComponent({
  name: 'PImportReport',
  components: { PHeader, PImportReportDetail },
  props: {
    importReport: {
      type: Object as PropType<ImportReport>,
      required: true,
    },
    error: { type: Boolean, required: true },
  },
  setup(props) {
    const { importReport } = toRefs(props)

    const i18n = use18n()

    const importReportTypeLabel = computed(() => importReport.value.type)

    const breadcrumbsItems = computed(() => [
      {
        label: i18n.t('breadcrumbs.home'),
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: i18n.t('breadcrumbs.parameters'),
        link: { name: 'parameters' },
      },
      {
        label: i18n.t('breadcrumbs.history_import'),
        link: { name: 'import-reports' },
      },
      { label: i18n.t(`ctx.import.report__details-${importReportTypeLabel.value}`) },
    ])

    const title = computed(() => i18n.t(`ctx.import.report__details-${importReportTypeLabel.value}`))

    return {
      breadcrumbsItems,
      title,
    }
  },
})
