<template>
  <div class="dropdown">
    <slot name="button-content">
      <div class="dropbtn">
        <button type="button">Toggle</button>
      </div>
    </slot>
    <div class="dropdown-content" data-cy="dropdown-content">
      <slot name="dropdown-content">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PDropdown',
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: var(--idf-color-neutral-200);
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  white-space: nowrap;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: var(--idf-color-main-100);
}
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
