
import { computed, defineComponent, Ref } from 'vue'
import { LogisticOfferFormData } from '../../../domain'
import { useDateFormatter, useLogisticOfferWording } from '../../composables'
import PSummaryLine from '../PSummaryLine/PSummaryLine.vue'
import PDeliveryNamePreview from '../PDeliveryNamePreview/PDeliveryNamePreview.vue'

export default defineComponent({
  name: 'PSummary',
  components: {
    PDeliveryNamePreview,
    PSummaryLine,
  },
  props: {
    logisticOffer: {
      type: Object as () => LogisticOfferFormData,
      required: true,
    },
  },
  setup(props) {
    const { formatPeriodToLocale } = useDateFormatter()
    const { wording } = useLogisticOfferWording()
    const validityPeriod: Ref<string> = computed(() =>
      formatPeriodToLocale(
        props.logisticOffer.validityPeriod?.start,
        props.logisticOffer.validityPeriod?.end,
        wording.dates.connector,
      ),
    )
    return { validityPeriod, wording }
  },
})
