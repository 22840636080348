
import { computed, defineComponent, PropType, toRefs } from 'vue'
import CFileDownload from './CFileDownload.vue'

export default defineComponent({
  name: 'CAssociatedParticipants',
  components: { CFileDownload },
  props: {
    participants: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup(props) {
    const { participants } = toRefs(props)

    return {
      fileContent: computed(() => `seller_id\r\n${participants.value.join('\r\n')}`),
      placeholder: computed(() => `${participants.value.length} structures associées`),
    }
  },
})
