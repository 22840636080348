
import { defineComponent } from 'vue'
import { IdfInput, IdfSelect } from '@invivodf/ui-kit'
import { ValidationProvider } from 'vee-validate-last'
import { PricingTypeOption } from '@invivodf/common/src/contexts/logistic-offer/domain/interfaces'
import PPricingScale from '../PPricingScale/PPricingScale.vue'
import { Pricing } from '../../../domain'
import { useLogisticOfferRules, usePriceUnits, useLogisticOfferWording } from '../../composables'

export default defineComponent({
  name: 'PPricingChoice',
  components: {
    IdfInput,
    IdfSelect,
    PPricingScale,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object as () => Pricing,
      required: true,
    },
    isStandardPricingType: {
      type: Boolean,
      required: true,
    },
    isAdvancedPricingType: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { wording } = useLogisticOfferWording()
    const pricingTypes: PricingTypeOption[] = [
      {
        value: 'order',
        label: wording.editor.pricing.types.global,
      },
      {
        value: 'logistic_order',
        label: wording.editor.pricing.types.logistic,
      },
      {
        value: 'advanced',
        label: wording.editor.pricing.types.advanced,
      },
    ]
    const { priceUnits } = usePriceUnits()
    const rules = useLogisticOfferRules()
    return { pricingTypes, priceUnits, rules, wording }
  },
})
