import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

export default class PartnerContactService {
  constructor(membershipApiHost) {
    this.membershipApiHost = membershipApiHost
    this.api = createAxiosClientWithAladinBearer(`${membershipApiHost}`)
  }

  async import(partnerId, file) {
    return ImportService.import(`${this.membershipApiHost}/backoffice/v1/import/partner/${partnerId}/contacts`, file)
  }

  async downloadPartnerContact(partnerId, partnerName) {
    const response = await this.api.get(`/backoffice/v1/export/partner/${partnerId}/contacts`, {
      headers: { accept: 'text/csv' },
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(partnerName, 'partner-contact'))
    document.body.appendChild(link)
    link.click()
  }
}
