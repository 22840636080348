import VueI18n from 'vue-i18n'
import { provideVue18n } from './use18n'

import fr_farmi from './farmi/fr-FR.json'
import fr_aladin from './aladin/fr-FR.json'
import fr_episens from './episens/fr-FR.json'
import fr_default from './default/fr-FR.json'

import fr_emoji from './emoji/fr-FR.json'

export const localeList = {
  aladin: {
    'fr-FR': fr_aladin,
  },
  farmi: {
    'fr-FR': fr_farmi,
  },
  episens: {
    'fr-FR': fr_episens,
  },
  emoji: {
    'fr-FR': fr_emoji,
  },
}

export function initPlugin(lang, Vue) {
  const customLocale = localStorage.getItem('customLocale')
  const brandName = customLocale || window.env.VUE_APP_CONTEXT
  const locale = lang

  Vue.use(VueI18n)

  const i18n = new VueI18n({
    messages: { ...localeList[brandName], fr: fr_default },
    locale,
    fallbackLocale: `fr`,
  })

  provideVue18n(i18n)

  return i18n
}
