import { CategorieApiResponse, ProduitCoreApiResponse } from '@b2ag/module-product-sdk'

interface Item {
  label: string
  link?: { name: string; query?: Record<string, string>; params?: Record<string, string> }
  icon?: string
}

// TODO: Rework the breadcrumb system to not have to do this mess on a page
// Solutions can be to make it listen changes to the router.
export function buildBreadcrumbs(
  product: Pick<ProduitCoreApiResponse, '_id' | 'name'>,
  categories: CategorieApiResponse[],
  productCatalogRoute,
): Item[] {
  const breadcrumbsItems: Item[] = [
    {
      label: 'Accueil',
      link: { name: 'home' },
      icon: 'home-filled',
    },
  ]
  if (categories && categories.length) {
    categories.forEach((category, index) => {
      breadcrumbsItems.push({
        label: category.label,
        link: {
          name: productCatalogRoute,
          query: {
            ...(index > 0 ? breadcrumbsItems[index]?.link?.query : {}),
            [`hierarchicalMenu[categoriesLevel0][${index}]`]: category.label,
          },
        },
      })
    })
  }
  breadcrumbsItems.push({
    label: product.name,
    link: {
      name: 'product',
      params: { productId: product._id },
    },
  })
  return breadcrumbsItems
}
