export const AvailablePaymentMethods = {
  SEPA: 'sepa',
  COOPERATIVE_ACCOUNT: 'cooperative_account',
  CARD: 'card',
}

export const PaymentMethodKinds = [
  {
    key: 'coop',
    value: AvailablePaymentMethods.COOPERATIVE_ACCOUNT,
  },
  { key: 'sepa', value: AvailablePaymentMethods.SEPA },
  {
    key: 'cb',
    value: AvailablePaymentMethods.CARD,
  },
]
