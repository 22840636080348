<template>
  <div class="import-stock" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :importFileService="stockService"
      :csvProperties="csvProperties"
      :pageTitle="pageTitle"
      :uploadButtonLabel="uploadButtonLabel"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        :title="title"
        :nbResultText="nbResultText"
        :exportText="exportText"
        :importInformation="importInformation"
        :downloadCSV="downloadStocksCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'
import { stockService } from '../../services'
import ImportService from '../../services/import.service'

export default defineComponent({
  name: 'CImportStock',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()

    const pageTitle = computed(() => {
      return i18n.t('ctx.import.stocks__page-title').toString()
    })

    const uploadButtonLabel = computed(() => {
      return i18n.t('ctx.import.stocks__upload-button-label').toString()
    })

    const title = computed(() => {
      return i18n.t('ctx.import.stocks__information-title').toString()
    })

    const nbResultText = computed(() => {
      return i18n.t('ctx.import.stocks__information-result-number').toString()
    })

    const exportText = computed(() => {
      return i18n.t('ctx.import.stocks__information-export-text').toString()
    })

    // TODO (LOBI 31/07) Don't forget feature flip
    const csvProperties = ref({
      name: 'stocks.csv',
      full_path: '/assets/csv/stocks.csv',
    })

    const importInformation = ref({
      count: null,
      lastImportAt: null,
    })

    const csvColumnDetails = ref([
      {
        header: 'variant_id',
        description: i18n.t('ctx.import.stocks__csv-column-details__variant-id-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: 'INV0001112223334SEM',
      },
      {
        header: 'store_code',
        description: i18n.t('ctx.import.stocks__csv-column-details__store-code-description').toString(),
        format: 'VARCHAR',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: '1211',
      },
      {
        header: 'store_quantity',
        description: i18n.t('ctx.import.stocks__csv-column-details__store-quantity-description').toString(),
        format: 'DECIMAL',
        mandatory: i18n.t('message.yes').toString(),
        exampleValue: '15.7',
      },
    ])

    const descriptionLines = [
      `${i18n.t('ctx.import.common__description-line-1')}<b>.csv</b>`,
      `${i18n.t('ctx.import.common__description-line-2')}<code>;</code>.`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.common__description-line-4')}<b>UTF8</b>.`,
    ]

    const cooperative = computed(() => store.getters.cooperative)

    const downloadStocksCSV = async () => {
      const storesCSV = await stockService.downloadStocks(cooperative.value.id)
      const url = window.URL.createObjectURL(new Blob([storesCSV]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', ImportService.createFileName(cooperative.value.name, 'stocks'))
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    }

    return {
      csvProperties,
      stockService,
      importInformation,
      csvColumnDetails,
      descriptionLines,
      pageTitle,
      uploadButtonLabel,
      title,
      nbResultText,
      exportText,
      downloadStocksCSV,
      cooperative,
    }
  },
})
</script>
