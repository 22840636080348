// eslint-disable-next-line import/prefer-default-export
export function mapFromApi(cooperative = {}) {
  return {
    id: cooperative.id,
    name: cooperative.name,
    lastMembershipsImportAt: cooperative.last_memberships_import_at,
    membershipCount: cooperative.membership_count,
    lastShippingAddressesImportAt: cooperative.last_shipping_addresses_import_at,
    shippingAddressesCount: cooperative.shipping_addresses_count,
    storesCount: cooperative.stores_count,
    lastStoresImportAt: cooperative.last_stores_import_at,
    lastTechnicalSalesImportAt: cooperative.last_technical_sales_import_at,
    technicalSalesCount: cooperative.technical_sales_count,
    customerContactsCount: cooperative.customer_contacts_count,
    lastCustomerContactsImportAt: cooperative.last_customer_contacts_import_at,
  }
}
