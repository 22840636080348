<template>
  <div class="import-members" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :descriptionLines="descriptionLines"
      :importFileService="membershipService"
      :csvProperties="csvProperties"
      :pageTitle="capitalize(`${$t('message.memberships')}`)"
      :importSectionTitle="`${$t('ctx.import.customers__title')}`"
      :uploadButtonLabel="`${$t('ctx.import.customers__description')}`"
      :csvColumnDetails="csvColumnDetails"
      :importInformation="importInformation"
      @afterLoading="afterMembershipImport"
    >
      <c-import-information
        :title="`${$t('ctx.import.customers__informations')}`"
        :nbResultText="`${$t('ctx.import.customers__count')}:`"
        :exportText="`${$t('ctx.import.customers__export-action')}`"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppContext } from '@invivodf/common/app.context'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { capitalize } from 'lodash'
import { use18n } from '@invivodf/locales/src/use18n'
import { cooperativeService, membershipService } from '../../services'

const csvFileProperties = {
  name: 'memberships.csv',
  full_path: '/assets/csv/memberships.csv',
}
export default {
  name: 'CImportMembers',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      membershipService,
      commonCsvColumnDetails: [
        {
          header: 'member_company_name',
          format: 'VARCHAR',
          exampleValue: 'Bonpoint Père & fils',
          description: `La raison sociale de ${i18n.t('message.membership-app-l')}.`,
          mandatory: 'Oui',
        },
        {
          header: 'member_siret_number',
          format: 'VARCHAR',
          exampleValue: '36252187900034',
          description: `Le numéro siret de ${i18n.t('message.membership-app-l')}.`,
          mandatory: 'Non',
        },
        {
          header: 'member_first_name',
          format: 'VARCHAR',
          exampleValue: 'Sophie',
          description: "Le prénom du contact principal de l'entreprise.",
          mandatory: 'Oui',
        },
        {
          header: 'member_last_name',
          format: 'VARCHAR',
          exampleValue: 'Bonpoint',
          description: "Le nom du contact principal de l'entreprise.",
          mandatory: 'Oui',
        },
        {
          header: 'member_code',
          format: 'VARCHAR',
          exampleValue: '1234AZE',
          description: `Le code de ${i18n.t('message.membership-app-l')} ou client avec lequel ${i18n.t(
            'message.membership-app-l',
          )} est enregistrée.`,
          mandatory: 'Oui',
        },
        {
          header: 'member_email',
          format: 'VARCHAR',
          exampleValue: 'foo@bar.example',
          description: `L’email avec lequel on puisse contacter ${i18n.t(
            'message.membership-app-l',
          )}. Cette information est notamment utilisée en tant que second facteur pour la validation des comptes. Une adresse email vide est accepté, mais cela signifie que le propriétaire n’est pas encore connu.`,
          mandatory: 'Non',
        },
        {
          header: 'member_phone_number',
          format: 'VARCHAR',
          exampleValue: '0611121314',
          description: "Le numéro de téléphone du contact principal de l'entreprise.",
          mandatory: 'Non',
        },
        {
          header: 'member_valid_certiphyto',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE',
          description: "Valeur permettant de déterminer si au moins un certiphyto est valide pour l'entreprise.",
          mandatory: 'Oui',
        },
        {
          header: 'member_certiphyto_end_date',
          format: 'DATE',
          exampleValue: '2020-08-15',
          description: "Valeur permettant de déterminer la date de fin de validité du certiphyto pour l'entreprise.",
          mandatory: 'Non',
        },
        {
          header: 'member_valid_explosives_precursor',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE',
          description:
            "Valeur permettant de déterminer si au moins un certificat de précurseurs d'explosifs est valide pour l'entreprise.",
          mandatory: 'Oui',
        },
        {
          header: 'member_explosives_precursor_end_date',
          format: 'DATE',
          exampleValue: '2023-01-01',
          description:
            "Valeur permettant de déterminer la date de fin de validité du certificat de précuseurs d'explosifs pour l'entreprise.",
          mandatory: 'Non',
        },
        {
          header: 'member_solvency',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE',
          description: i18n.t('ctx.user.member-solvency'),
          mandatory: 'Oui',
        },
        {
          header: 'member_sepa_payment',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE',
          description: i18n.t('ctx.user.member-sepa'),
          mandatory: 'Oui',
        },
        {
          header: 'member_card_payment',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE ou vide',
          description: `Valeur permettant de déterminer si peut utiliser le mode de paiement par carte. Un champs vide est équivalent à FALSE`,
          mandatory: 'Oui',
        },
        {
          header: 'member_coop_account_maximum_allowed_amount',
          format: 'DECIMAL',
          exampleValue: '5000.50',
          description: `Montant maximum autorisé pour le paiement par compte ${i18n.t(
            'message.partner',
          )} pour l'entreprise.`,
          mandatory: 'Oui',
        },
        {
          header: 'member_coop_account_payment',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE',
          description: `Valeur permettant de déterminer si peut utiliser le mode de paiement compte marque.`,
          mandatory: 'Oui',
        },
        {
          header: 'member_segment',
          format: 'VARCHAR',
          exampleValue: 'VIP',
          description: `Segment ${i18n.t('message.membership')}`,
          mandatory: 'Non',
        },
        {
          header: 'member_farming_types',
          format: 'LIST',
          exampleValue: 'BLES|MAIS',
          description: i18n.t('ctx.user.member-farming-types'),
          mandatory: 'Non',
        },
        {
          header: 'member_region',
          format: 'VARCHAR',
          exampleValue: 'Paris nord',
          description: i18n.t('ctx.user.member-region'),
          mandatory: 'Non',
        },
        {
          header: 'member_default_store_id',
          format: 'VARCHAR',
          exampleValue: 'MAGASIN Paris',
          description: i18n.t('ctx.user.member-store'),
          mandatory: 'Non',
        },
      ],
      farmiCsvColumnDetails: [
        {
          header: 'member_bank_account_id',
          format: 'VARCHAR',
          exampleValue: '46034066',
          description: 'Remplit si le client a un compte courant et vide s’il n’en a pas.',
          mandatory: 'Non',
        },
      ],
      aladinCsvColumnDetails: [
        {
          header: 'member_consent',
          format: 'BOOLEAN',
          exampleValue: 'TRUE, FALSE, <VIDE>',
          description:
            "Permet de savoir si un adhérent au sein d'un partenaire consent au recueille de données de navigation",
          mandatory: 'Non',
        },
      ],
      descriptionLines: [
        i18n.t('ctx.import.customers__advise-1'),
        i18n.t('ctx.import.customers__advise-2'),
        i18n.t('ctx.import.customers__advise-3'),
        i18n.t('ctx.import.customers__advise-4'),
      ],
    }
  },
  computed: {
    ...mapGetters(['membershipImportInformation', 'cooperativeId', 'cooperative']),
    importInformation() {
      const { membershipImportInformation: { membershipCount, lastMembershipsImportAt } = {} } = this
      return { count: membershipCount, lastImportAt: lastMembershipsImportAt }
    },
    csvColumnDetails() {
      if (window.env.VUE_APP_CONTEXT === AppContext.farmi) {
        return [...this.commonCsvColumnDetails, ...this.farmiCsvColumnDetails]
      }
      if (window.env.VUE_APP_CONTEXT === AppContext.aladin) {
        return [...this.commonCsvColumnDetails, ...this.aladinCsvColumnDetails]
      }
      return this.commonCsvColumnDetails
    },
  },
  methods: {
    capitalize,
    afterMembershipImport() {
      this.$store.dispatch('setMembershipImportInformation')
    },
    downloadCSV() {
      return cooperativeService.downloadMemberships(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
