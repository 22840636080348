<template>
  <div class="card">
    <h2 class="title mb-4">{{ $t('ctx.import.zone-charges__form-title') }}</h2>
    <span class="zone-form__zone-error" v-if="error">{{ error }}</span>
    <template v-else>
      <ul class="zone-form__zones-list">
        <li v-for="(zone, i) in zones" class="list-unstyled" :key="i">
          <div class="zone-form__zones-list__item">
            <label class="zone-form__zone-label" :for="'zone-' + i">
              {{ $t('ctx.import.zone-charges__form-zone-label', { rank: i + 1 }) }}
            </label>
            <label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-valid': zone.updateStatus === 'valid',
                  'is-invalid': zone.updateStatus === 'invalid',
                }"
                :name="'zone-' + i"
                :id="'zone-' + i"
                :placeholder="$t('ctx.import.zone-charges__form-placeholder', { rank: i + 1 })"
                @blur.prevent="saveZone(zone)"
                v-model="zone.name"
                data-test="input-zone"
              />
            </label>
            <IdfButton
              kind="link"
              type="button"
              class="zone-form__btn-delete-zone ml-4"
              data-test="btn-delete-zone"
              @click="deleteZone(zone)"
              icon-left="close"
            >
              {{ $t('ctx.import.zone-charges__form-delete-zone') }}
            </IdfButton>
            <span></span>
            <span class="zone-form__zone-error" data-test="zone-error">{{ zone.error }}</span>
          </div>
        </li>
      </ul>
      <div>
        <IdfButton kind="link" type="button" @click="addZone" data-test="btn-add-zone" icon-left="plus">
          {{ $t('ctx.import.zone-charges__form-add-zone') }}
        </IdfButton>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { IdfButton } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { zoneService } from '@/services'

export const UPDATE_SUCCEEDED = 'valid'
export const UPDATE_FAILED = 'invalid'
export const UPDATE_DONE = undefined
export const UPDATE_TIMEOUT = 3000

export default {
  name: 'CZoneForm',
  components: { IdfButton },
  data() {
    const i18n = use18n()

    return {
      zones: [],
      error: '',
      i18n,
    }
  },
  computed: {
    ...mapGetters(['cooperativeId']),
  },
  async mounted() {
    await this.fetchZones()
  },
  methods: {
    async fetchZones() {
      try {
        this.zones = await zoneService.findAll({ cooperativeId: this.cooperativeId })
      } catch (error) {
        this.error = error && error.message
      }
    },
    async saveZone(zone) {
      const zoneIndex = this.zones.indexOf(zone)
      const zoneError = this.validateZone(zone)
      let zoneToProcess = zone
      this.$set(zone, 'error', zoneError)
      if (zoneError) {
        this.$set(zone, 'updateStatus', UPDATE_FAILED)
        return
      }
      try {
        delete zoneToProcess.updateStatus
        if (!zoneToProcess.id) {
          zoneToProcess = await zoneService.create({ cooperativeId: this.cooperativeId, zone })
        } else {
          zoneToProcess = await zoneService.update({ cooperativeId: this.cooperativeId, zone })
        }
        this.zones.splice(zoneIndex, 1, zoneToProcess)
        this.$set(zoneToProcess, 'updateStatus', UPDATE_SUCCEEDED)
      } catch (error) {
        this.$set(zoneToProcess, 'updateStatus', UPDATE_FAILED)
      } finally {
        setTimeout(() => {
          this.$set(zoneToProcess, 'updateStatus', UPDATE_DONE)
        }, UPDATE_TIMEOUT)
      }
    },
    async deleteZone(zone) {
      if (zone.id !== undefined) {
        await zoneService.delete({ cooperativeId: this.cooperativeId, zone })
      }
      this.zones.splice(this.zones.indexOf(zone), 1)
    },
    addZone() {
      this.zones.push({})
    },
    validateZone(zone) {
      if (!zone.name) return this.i18n.t('ctx.import.zone-charges__form-validate-zone-placeholder')
      if (zone.name.match(new RegExp(';'))) return this.i18n.t('ctx.import.zone-charges__form-validate-zone-semicolon')
      if (
        this.zones &&
        this.zones
          .filter((zoneToChecked) => zoneToChecked !== zone)
          .map((zoneToChecked) => zoneToChecked.name)
          .includes(zone.name)
      )
        return this.i18n.t('ctx.import.zone-charges__form-validate-zone-already-exist')
      return undefined
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../lib/variables';
.zone-form__zones-list {
  padding-left: 0;
  margin: 0;
  .zone-form__zone-label {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 8px;
    grid-area: label;
  }
  .form-control {
    grid-area: input;
  }
  .zone-form__zones-list__item {
    display: grid;
    grid-template-columns: 1fr 12fr 2fr;
    grid-template-areas:
      'label input button'
      '. error error';
    margin-bottom: 8px;
  }
  .zone-form__btn-delete-zone {
    white-space: nowrap;
    grid-area: button;
    padding: 0;
  }
}
.zone-form__zone-error {
  grid-area: error;
  color: var(--idf-color-error-400);
}
</style>
