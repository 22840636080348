<template>
  <c-upload-csv
    :csvProperties="{}"
    :sectionTitle="$t('ctx.import.zone-charges__upload-section-title')"
    :label="$t('ctx.import.zone-charges__upload-label')"
    data-test="c-upload-csv"
    @send-file="importCsv"
  >
    <template slot="template-download-link">
      <IdfButton
        type="button"
        kind="link"
        class="csv-download-link"
        @click="downloadZoneTemplate"
        icon-left="download"
        iconPosition="left"
      >
        {{ $t('ctx.import.zone-charges__upload-download') }}
      </IdfButton>
    </template>
    <template slot="template-upload-link">
      <IdfButton type="submit" data-test="upload-btn">
        {{ $t('ctx.import.zone-charges__upload-submit') }}
      </IdfButton>
    </template>
  </c-upload-csv>
</template>

<script>
import { mapGetters } from 'vuex'
import { IdfButton } from '@invivodf/ui-kit'
import { CUploadCsv } from '@invivodf/common/src/contexts/parameters'
import { zoneService } from '@/services'

export default {
  name: 'CZoneUpload',
  components: { CUploadCsv, IdfButton },
  computed: {
    ...mapGetters(['cooperativeId']),
  },
  methods: {
    async downloadZoneTemplate() {
      const csvZoneTemplate = await zoneService.downloadZoneTemplate({ cooperativeId: this.cooperativeId })
      const url = window.URL.createObjectURL(new Blob([csvZoneTemplate]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', 'zones-charges.csv')
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
    importCsv(file) {
      this.$emit('send-file', file)
    },
  },
}
</script>
