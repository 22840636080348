<template>
  <div
    data-test="order"
    :data-cy="'order-informations-' + order.id"
    :key="'order-' + order.id"
    :class="{
      'order__row--active': order.show,
      order__row: true,
    }"
  >
    <div
      :class="{
        order__row__header__action: !displayTracking,
        'pt-2 pb-2 order__row__header': true,
      }"
    >
      <div>
        <IdfButtonIcon
          data-test="btn-toggle"
          @click.stop="toggleShow(order)"
          :icon="order.show ? 'chevron-up' : 'chevron-down'"
        />
      </div>
      <div class="order__align--left" data-cy="order-owner">
        <template v-if="order.owner">
          <p v-if="order.owner.company_name" class="user-company_name" data-test="user-infos">
            {{ order.owner.company_name }}
          </p>
          <p class="user-membership_number" data-test="user-membership_number">{{ order.owner.membership_number }}</p>
        </template>
      </div>
      <div data-cy="order-id">{{ order.id }}</div>
      <div>
        <input
          v-if="order.status === pendingStatus"
          type="text"
          data-test="update-erp-id"
          data-cy="order-erp-id"
          class="form-control"
          :class="{
            'is-valid': order.updateStatus === 'valid',
            'is-invalid': order.updateStatus === 'invalid',
          }"
          :aria-label="$t('ctx.orders.orders.list.rows.inputErp')"
          :state="order.updateStatus"
          :value="order.erp_order_id"
          :disabled="order.status !== pendingStatus"
          @focus.stop="disableButton(order)"
          @blur.stop="updateOrder(order, 'erp_order_id', $event.target.value)"
          @keyup.enter="$event.target.blur()"
        />
        <p data-cy="order-erp-id-disable" v-if="order.status !== pendingStatus">{{ order.erp_order_id }}</p>
      </div>
      <div data-cy="order-total-quantity">{{ totalQuantity(order) }}</div>
      <div v-if="!displayTracking" @click="displayPriceDetails()">
        <div data-test="total-ttc" data-cy="order-total-ttc" class="text-nowrap">
          <span class="mr-1">{{ order.total_ttc | currency }}</span>
          <c-tooltip ref="priceDetailTooltip" :title="$t('ctx.orders.orders.list.rows.priceTooltip.title')">
            <ul class="key-value-list">
              <li class="key-value-item">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalPriceHT') }}</span>
                <span>{{ order.cart && order.cart.total_price_with_discount | currency }}</span>
              </li>
              <li class="key-value-item">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalRPD') }}</span>
                <span>{{ order.total_taxes.rpd || 0 | currency }}</span>
              </li>
              <li class="key-value-item mb-3">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalPaymentDueDate') }}</span>
                <span>{{ order.cart.total_payment_due_dates_price || 0 | currency }}</span>
              </li>
              <li class="key-value-item">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalPriceWithoutVAT') }}</span>
                <span
                  >{{ +order.cart.total_price_without_VAT + order.cart.total_payment_due_dates_price || 0 | currency }}
                </span>
              </li>
              <li class="key-value-item mb-3">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalVAT') }}</span
                ><span>{{ order.total_taxes.tva || 0 | currency }}</span>
              </li>

              <li class="key-value-item mb-3">
                <span>{{ $t('ctx.orders.orders.list.rows.priceTooltip.shippingCost') }}</span
                ><span>{{ order.shipping && order.shipping.cost | currency }}</span>
              </li>
              <li class="key-value-item">
                <span>
                  <strong>{{ $t('ctx.orders.orders.list.rows.priceTooltip.totalPriceTTC') }}</strong>
                </span>
                <span
                  ><strong>{{ order.total_ttc | currency }}</strong>
                </span>
              </li>
            </ul>
          </c-tooltip>
        </div>
      </div>
      <div data-cy="order-created-at">
        <div>
          <p>{{ order.created_at | date }}</p>
          <p class="text-color-grey">{{ order.created_at | time }}</p>
        </div>
      </div>
      <div data-cy="order-method">
        <template v-if="!displayTracking">{{ order.payment_method && getPaymentMethodTitle(order) }}</template>
        <template v-else>{{ order.shipping && order.shipping.title }}</template>
        <span v-if="getPaymentDueDates(order)" class="payment-due-date-number">{{ getPaymentDueDates(order) }}</span>
      </div>
      <div
        v-if="!displayTracking && order.status === 'pending'"
        data-cy="order-more-actions"
        data-test="order-more-actions"
      >
        <p-dropdown>
          <template slot="button-content">
            <IdfIcon
              kind="three-dots-vertical"
              :aria-label="$t('ctx.orders.orders.list.rows.moreActions.ariaLabel')"
            ></IdfIcon>
          </template>
          <template slot="dropdown-content">
            <a href="#" @click.prevent="acceptOrder(order)" data-cy="btn-accept-order">{{
              $t('ctx.orders.orders.list.rows.moreActions.acceptOrder')
            }}</a>
            <a href="#" @click.prevent="cancelOrder(order)">{{
              $t('ctx.orders.orders.list.rows.moreActions.cancelOrder')
            }}</a>
          </template>
        </p-dropdown>
      </div>
    </div>
    <div>
      <c-line-items-row
        key="line-items"
        :order="order"
        :displayTracking="displayTracking"
        data-test="line-item-row"
        @updateLineItemsStatus="updateLineItemsStatus"
        @showModalShippingInfos="showModalShippingInfos"
        @showPriceDetailByLineItemTooltip="displayLineItemPriceDetails"
      >
      </c-line-items-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date, time } from '@invivodf/common/src/application/filters'
import { IdfButtonIcon, IdfIcon } from '@invivodf/ui-kit'
import CLineItemsRow from '@/components/COrderListRow/CLineItemsRow/CLineItemsRow.vue'
import { currency } from '@/filters'
import CTooltip from '@/components/CTooltip/CTooltip.vue'
import PDropdown from '@/presenters/PDropdown/PDropdown.vue'

const STATUS_PENDING = 'pending'

export default {
  name: 'COrderRow',
  components: { CTooltip, PDropdown, CLineItemsRow, IdfButtonIcon, IdfIcon },
  data: () => ({
    pendingStatus: STATUS_PENDING,
  }),
  props: {
    displayTracking: Boolean,
    order: Object,
  },
  filters: {
    date,
    time,
    currency,
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperativeName']),
    totalQuantity() {
      return (order) =>
        ((order.cart && order.cart.line_items) || [])
          .map((lineItem) => lineItem.quantity || 1)
          .reduce((acc, lineItemQuantity) => acc + lineItemQuantity, 0)
    },
  },
  methods: {
    disableButton(order) {
      this.$set(order, 'disableButton', true)
    },
    toggleShow(order) {
      this.$emit('toggleShow', order)
    },
    displayPriceDetails() {
      this.$emit('showPriceDetailTooltip', this.$refs.priceDetailTooltip)
    },
    fulfillOrder(order) {
      this.$emit('fulfillOrder', order)
    },
    updateOrder(order, prop, value) {
      this.$emit('updateOrder', order, prop, value)
    },
    acceptOrder(order) {
      this.$emit('acceptOrder', order)
    },
    cancelOrder(order) {
      this.$emit('cancelOrder', order)
    },

    updateLineItemsStatus(order, lineItems) {
      this.$emit('updateLineItemsStatus', order, lineItems)
    },
    showModalShippingInfos(order) {
      this.$emit('showModalShippingInfos', order)
    },

    displayLineItemPriceDetails(priceDetail) {
      this.$emit('showPriceDetailByLineItemTooltip', priceDetail)
    },
    getPaymentMethodTitle(order) {
      if (order.payment_method.short_title === 'Compte COOP') {
        return order.payment_method.short_title.replace('COOP', this.cooperativeName)
      }
      return order.payment_method.short_title
    },
    getPaymentDueDates(order) {
      const paymentDueDates = order.cart.line_items
        ?.map((lineItem) => lineItem.payment_due_date)
        .filter((paymentDueDate) => paymentDueDate)
      if (paymentDueDates?.length) {
        return `${paymentDueDates.length} ${this.$t(`ctx.orders.orders.list.rows.${paymentDueDates.length > 1 ? 'paymentDueDates' : 'paymentDueDate'}`)}`
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.user-company_name {
  text-align: left;
}
.user-membership_number {
  text-align: left;
  font-weight: 700 !important;
}
.order__row {
  display: grid;
  border: 1px solid var(--idf-color-neutral-200);
  border-radius: var(--idf-border-radius-m);
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-out;
}
.order__row--active {
  box-shadow:
    0 2px 2px rgba(0, 0, 0, 0.16),
    0 2px 6px rgba(0, 0, 0, 0.23);
  transition: box-shadow 0.2s ease-in;
}
.order__row__header {
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 1fr;
}
.order__row__header__action {
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
}
.order__row__header > .order__align--left {
  justify-content: left;
}
.order__row__header > div:not(.order__align--left) {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.order__row__header > div:first-child {
  vertical-align: middle;
}
.payment-due-date-number {
  font-size: 1.2rem;
  color: var(--idf-color-neutral-400);
}
</style>
