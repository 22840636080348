<template>
  <div>
    <c-import-base
      :importFileService="offerService"
      :csvProperties="csvProperties"
      :pageTitle="$t('ctx.import.offers__page-title')"
      :uploadButtonLabel="$t('ctx.import.offers__upload-button-label')"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
      :reportWarning="$t('ctx.import.offers__report-warning')"
    >
      <c-import-information
        :title="$t('ctx.import.offers__import-information-title')"
        :nbResultText="$t('ctx.import.offers__nb-result-text')"
        :exportText="$t('ctx.import.offers__export-text')"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { offerService } from '../../services'
import { offerColumnDetails, offerDescriptionLines } from '../../services/offer-column-details.const'

const csvFileProperties = {
  name: 'offers.csv',
  full_path: '/assets/csv/offers.csv',
}
export default {
  name: 'CImportOffers',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      offerService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
      csvColumnDetails: offerColumnDetails(i18n),
      descriptionLines: offerDescriptionLines,
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return offerService.downloadOffers(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
