<template>
  <section class="offer-filters">
    <form class="offer-filters__container" @submit.prevent="filter" @reset="resetForm" data-test-filter-form>
      <h1 class="offer-filters__title">{{ $t('ctx.offers.filters.title') }}</h1>
      <fieldset class="offer-filters__block">
        <label class="offer-filters__search">
          <input
            name="variantName"
            type="search"
            v-model="filters.variantName"
            :placeholder="$t('ctx.offers.filters.variantNameInputPlaceholder')"
            data-test-variant-name
          />
          <svg viewBox="0 0 10 10">
            <title>{{ $t('ctx.offers.filters.searchTitle') }}</title>
            <circle cx="3.6" cy="3.6" r="3" />
            <line x1="5.5" y1="5.5" x2="9" y2="9" />
          </svg>
        </label>
      </fieldset>
      <fieldset class="offer-filters__block">
        <h1 class="offer-filters__block-title">{{ $t('ctx.offers.filters.validityDateFrom') }}</h1>
        <label>
          {{ $t('ctx.offers.filters.from') }}
          <input
            type="date"
            :max="filters.validityIntervalEnd"
            name="validityIntervalStart"
            v-model="filters.validityIntervalStart"
            data-test-starts-from
          />
        </label>
        <label>
          {{ $t('ctx.offers.filters.to') }}
          <input
            type="date"
            :min="filters.validityIntervalStart"
            name="validityIntervalEnd"
            v-model="filters.validityIntervalEnd"
            data-test-expires-before
          />
        </label>
      </fieldset>
      <fieldset class="offer-filters__block">
        <h1 class="offer-filters__block-title">{{ $t('ctx.offers.filters.visibility') }}</h1>
        <label>
          <input
            type="radio"
            checked
            name="isTechExclusive"
            :value="undefined"
            v-model="filters.isTechExclusive"
            data-test-is-tech-exclusive
          />
          {{ $t('ctx.offers.filters.allOffers') }}
        </label>
        <label>
          <input
            type="radio"
            name="isTechExclusive"
            :value="true"
            v-model="filters.isTechExclusive"
            data-test-is-tech-exclusive
          />
          {{ $t('ctx.offers.filters.techOffers') }}
        </label>
        <label>
          <input
            type="radio"
            name="isTechExclusive"
            :value="false"
            v-model="filters.isTechExclusive"
            data-test-is-tech-exclusive
          />
          {{ $t('ctx.offers.filters.excludeTechOffers') }}
        </label>
      </fieldset>
      <fieldset class="offer-filters__block">
        <IdfButton type="submit">{{ $t('ctx.offers.filters.apply') }}</IdfButton>
        <IdfButton
          v-if="submitted"
          class="offer-filters__reset-button"
          kind="link"
          type="reset"
          iconLeft="refresh"
          data-test-reset-button
          >{{ $t('ctx.offers.filters.reset') }}</IdfButton
        >
      </fieldset>
    </form>
  </section>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

const defaultFilters = {
  validityIntervalEnd: '',
  validityIntervalStart: '',
  variantName: '',
  isTechExclusive: undefined,
}
export default {
  name: 'COfferFilters',
  components: {
    IdfButton,
  },
  data() {
    return {
      filters: { ...defaultFilters },
      submitted: false,
    }
  },
  methods: {
    filter() {
      this.$emit('filterOffers', this.filters)
      this.submitted = true
    },
    resetForm() {
      this.filters = { ...defaultFilters }
      this.filter()
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';

.offer-filters {
  margin-right: 50px;
  box-sizing: border-box;
}
.offer-filters__container {
  border: 1px solid var(--idf-color-neutral-200);
  padding: 20px;
  width: 255px;
  background-color: var(--idf-color-neutral-100);
}
.offer-filters__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.offer-filters__block {
  padding: 20px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // DEV-NOTE : all blocks except first one
  + .offer-filters__block {
    border-top: 1px solid var(--idf-color-neutral-200);
  }
}
.offer-filters__block-title {
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  color: #555d60;
  margin-bottom: 10px;
}
.offer-filters input,
.offer-filters__search svg {
  border: 1px solid var(--idf-color-neutral-500);
  box-sizing: border-box;
  height: 40px;
}
.offer-filters input {
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.offer-filters input[type='radio'] {
  border-radius: 4px;
  padding: 0;
  margin-right: 6px;
  width: initial;
  height: initial;
}
.offer-filters__search {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  flex-wrap: nowrap;
  border-radius: 4px;
  &:focus-within {
    outline-color: var(--idf-color-main-400);
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    -moz-outline-radius: 4px;
    outline-width: 5px;
    outline-offset: -2px;
  }
}
.offer-filters__search input {
  border-left: 0 none;
  border-radius: 0 4px 4px 0;
  flex: 1 1 auto;
  &:focus {
    outline: none;
  }
}
.offer-filters__search svg {
  padding: 6px 0 5px 9px;
  border-right: 0 none;
  border-radius: 4px 0 0 4px;
  fill: none;
  flex: 0 0 30px;
  stroke: var(--idf-color-neutral-400);
  stroke-width: 0.9px;
}
.offer-filters__reset-button {
  margin-top: var(--idf-spacing-1);
}
</style>
