<template>
  <div>
    <c-import-base
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
      :importFileService="zoneChargesService"
      :pageTitle="$t('ctx.import.zone-charges__page-title')"
      ref="importBase"
    >
      <c-zone-form />
      <c-import-information
        :title="$t('ctx.import.zone-charges__import-information-title')"
        :nbResultText="$t('ctx.import.zone-charges__nb-result-text')"
        :exportText="$t('ctx.import.zone-charges__export-text')"
        :downloadCSV="downloadCSVZoneCharges"
        :import-information="importInformation"
      >
      </c-import-information>
      <template slot="upload-csv">
        <c-zone-upload @send-file="importCsv" data-test="upload-csv" />
      </template>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import CZoneForm from '@/components/CZoneForm/CZoneForm.vue'
import CZoneUpload from '@/components/CZoneUpload/CZoneUpload.vue'
import { importReportsService, zoneChargesService } from '@/services'
import { use18n } from '@invivodf/locales/src/use18n'
import ImportService from '../../services/import.service'
import { zoneChargesColumnDetails, zoneChargesDescriptionLines } from './zone-charges-column-details.const'

export default {
  name: 'VZoneCharges',
  data() {
    const i18n = use18n()
    return {
      zoneChargesService,
      csvColumnDetails: zoneChargesColumnDetails(i18n),
      descriptionLines: zoneChargesDescriptionLines,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
    }
  },
  components: { CImportInformation, CImportBase, CZoneForm, CZoneUpload },
  computed: {
    ...mapGetters(['cooperative']),
  },
  async mounted() {
    const report = await importReportsService.getLastZoneChargesImportReport(this.cooperative.id)
    if (report) {
      this.importInformation.count = report.line_imported_count
      this.importInformation.lastImportAt = report.updated_at
    }
  },
  methods: {
    importCsv(file) {
      this.$refs.importBase.importCsv(file)
    },
    async downloadCSVZoneCharges() {
      const csvZoneCharges = await zoneChargesService.downloadZoneCharges(this.cooperative.id)
      const url = window.URL.createObjectURL(new Blob([csvZoneCharges]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', ImportService.createFileName(this.cooperative.name, 'zones-charges'))
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
  },
}
</script>
