/* eslint-disable no-shadow,no-use-before-define */
import algoliasearch from 'algoliasearch/lite'
import jwt from 'jsonwebtoken'
import Vue from 'vue'
import Vuex from 'vuex'
import { use18n } from '@invivodf/locales/src/use18n'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useAuthService } from '@/auth'

import {
  userService,
  productService,
  categoryService,
  cooperativeService,
  importReportsService,
  cooperativeCustomProductsV2Service,
} from '@/services'
import CategoryService from '@/services/category.service'
import toasts from './store/toasts.store'
import { getOfferStoreModule } from './store/offers.store'
import catalog from './store/catalog.store'
import stockQuantity from './store/stockQuantity.store'
import search from './store/search.store'
import quota from './store/quota.store'

Vue.use(Vuex)

const searchClient = algoliasearch(window.env.ALGOLIA_APP_ID, window.env.ALGOLIA_API_KEY)

const defaultUser = { infos: {} }

const globalState = {
  App: {
    isAuthenticated: false,
  },
  user: defaultUser,
  cooperative: {},
  categories: [],
  membershipImportInformation: {},
  shippingAddressesImportInformation: {},
  storesImportInformation: {},
  technicalSalesImportInformation: {},
  customProductImportInformation: {},
  partnerContactImportInformation: {},
  cooperativeIndex: null,
  isTech: false,
}

export const getters = {
  user(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.App.isAuthenticated
  },
  cooperative(state) {
    return state.cooperative
  },
  cooperativeId(state) {
    return state.cooperative.id
  },
  cooperativeName(state) {
    return state.cooperative.name
  },
  categories(state) {
    return state.categories
  },
  categoryHierarchies(state) {
    return state.categoryHierarchies
  },
  cooperativeIndex(state) {
    return state.cooperativeIndex
  },
  membershipImportInformation(state) {
    return state.membershipImportInformation
  },
  shippingAddressesImportInformation(state) {
    return state.shippingAddressesImportInformation
  },
  storesImportInformation(state) {
    return state.storesImportInformation
  },
  technicalSalesImportInformation(state) {
    return state.technicalSalesImportInformation
  },
  partnerContactImportInformation(state) {
    return state.partnerContactImportInformation
  },
  customProductImportInformation(state) {
    return state.customProductImportInformation
  },
  isTech(state) {
    return state.isTech
  },
  isUserLoaded(state) {
    return state.user !== defaultUser
  },
}

export const mutations = {
  SET_USER(state, user) {
    if (user) {
      state.user = { ...user, current_order: user.current_order || { line_items: [] } }
    }
  },
  RESET_USER(state) {
    state.user = defaultUser
    state.App.isAuthenticated = false
  },
  SET_AUTHENTICATED(state, isAuthenticated) {
    state.App.isAuthenticated = isAuthenticated
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_CATEGORY_HIERARCHIES(state, categoryHierarchies) {
    state.categoryHierarchies = categoryHierarchies
  },
  SET_COOPERATIVE(state, cooperative) {
    const i18n = use18n()
    const { locale } = i18n
    state.cooperative = cooperative
    state.cooperativeIndex = cooperative.id
      ? searchClient.initIndex(`${window.env.ALGOLIA_INDEX_COOP_PREFIX}${cooperative.id}.${locale}`)
      : null
  },
  SET_MEMBERSHIP_IMPORT_INFORMATION(state, cooperative) {
    state.membershipImportInformation = {
      lastMembershipsImportAt: cooperative.lastMembershipsImportAt,
      membershipCount: cooperative.membershipCount,
    }
  },
  SET_SHIPPING_ADDRESSES_IMPORT_INFORMATION(state, cooperative) {
    state.shippingAddressesImportInformation = {
      lastShippingAddressesImportAt: cooperative.lastShippingAddressesImportAt,
      shippingAddressesCount: cooperative.shippingAddressesCount,
    }
  },
  SET_STORES_IMPORT_INFORMATION(state, cooperative) {
    state.storesImportInformation = {
      lastStoresImportAt: cooperative.lastStoresImportAt,
      storesCount: cooperative.storesCount,
    }
  },
  SET_TECHNICAL_SALES_IMPORT_INFORMATION(state, cooperative) {
    state.technicalSalesImportInformation = {
      lastTechnicalSalesImportAt: cooperative.lastTechnicalSalesImportAt,
      technicalSalesCount: cooperative.technicalSalesCount,
    }
  },
  SET_PARTNER_CONTACT_IMPORT_INFORMATION(state, cooperative) {
    state.partnerContactImportInformation = {
      lastCustomerContactsImportAt: cooperative.lastCustomerContactsImportAt,
      customerContactsCount: cooperative.customerContactsCount,
    }
  },
  SET_CUSTOM_PRODUCT_IMPORT_INFORMATION(state, importReport) {
    state.customProductImportInformation = {
      lastCustomProductImportAt: importReport.lastCustomProductImportAt,
      customProductCount: importReport.customProductCount,
    }
  },
  SET_IS_TECH(state, isTech) {
    state.isTech = isTech
  },
}

export const actions = {
  initializeStore(context, cooperative) {
    if (cooperative) {
      context.commit('SET_COOPERATIVE', cooperative)
      context.dispatch('setMembershipImportInformation')
      context.dispatch('setShippingAddressesImportInformation')
      context.dispatch('setStoresImportInformation')
      context.dispatch('setTechnicalSalesImportInformation')
      context.dispatch('setPartnerContactImportInformation')
    }
  },
  async fetchUser(context) {
    /**
     * @type {import('@/auth').AuthService}
     */
    const authService = useAuthService()
    try {
      const [user, role] = await Promise.all([userService.get(), authService.getAladinSellerRole()])
      const sellerPartner = findSellerPartner(user, role)

      context.commit('SET_USER', user)
      context.commit('SET_AUTHENTICATED', true)
      context.dispatch('initializeStore', sellerPartner)
      context.dispatch('setIsTech')
    } catch (error) {
      if (error.response.status === 401) {
        authService.doLogout()
        if (this.$route && this.$route.path !== '/') {
          router.push({ name: 'home' })
        }
      }
    }
  },
  setUser(context, user) {
    context.commit('SET_USER', user)
  },
  async logout(context) {
    const authService = useAuthService()
    authService.doLogout()
    localStorage.removeItem('cooperative')
    context.commit('RESET_USER')
    context.commit('SET_COOPERATIVE', {})
    if (this.$route && this.$route.path !== '/') {
      router.push({ name: 'home' })
    }
  },
  async fetchCategories(context) {
    const categories = await categoryService.getV2Categories()
    context.commit('SET_CATEGORIES', categories)
    const hierarchies = CategoryService.computeHierarchies(categories)
    context.commit('SET_CATEGORY_HIERARCHIES', hierarchies)
    return categories
  },
  async updateReferentialProduct(context, { id, payload }) {
    let updatedPayload = payload
    if (payload.categories) {
      updatedPayload = {
        ...payload,
        categories: payload.categories.map(
          (categorieHierarchy) => categorieHierarchy[categorieHierarchy.length - 1].code,
        ),
      }
    }
    const product = await cooperativeCustomProductsV2Service.updateReferentialProduct(
      id,
      context.getters.cooperativeId,
      updatedPayload,
    )

    await context.dispatch('catalog/fetchProductAndOffers', id)
    return product
  },
  async updateCatalogProduct(context, { id, payload }) {
    const product = await productService.updateCatalogProduct(id, context.getters.cooperativeId, payload)
    context.dispatch('catalog/fetchProduct', id)
    return product
  },
  async saveProductCategories(context, payload) {
    const { productId, categories } = payload
    const product = context.getters['catalog/getProductById'](productId)
    await context.dispatch('updateReferentialProduct', {
      id: productId,
      payload: { ...product, categories },
    })
  },
  async saveProductCharacteristics(context, payload) {
    const { productId, characteristics } = payload
    const product = context.getters['catalog/getProductById'](productId)
    context.dispatch('updateReferentialProduct', {
      id: productId,
      payload: { ...product, characteristics },
    })
  },
  async saveProductDescription(context, payload) {
    const { productId, description } = payload
    const product = context.getters['catalog/getProductById'](productId)
    context.dispatch('updateReferentialProduct', {
      id: productId,
      payload: { ...product, description },
    })
  },
  async saveProductUpsides(context, review) {
    const { productId, upsides } = review
    const product = context.getters['catalog/getProductById'](productId)
    context.dispatch('updateCatalogProduct', { id: productId, payload: { ...product, upsides } })
  },
  async deleteProductUpsides(context, productId) {
    const product = context.getters['catalog/getProductById'](productId)
    context.dispatch('updateCatalogProduct', { id: productId, payload: { ...product, upsides: '' } })
  },
  async saveProductTips(context, review) {
    const { productId, tips } = review
    const product = context.getters['catalog/getProductById'](productId)
    await context.dispatch('updateCatalogProduct', { id: productId, payload: { ...product, tips } })
  },
  async deleteProductTips(context, productId) {
    const product = context.getters['catalog/getProductById'](productId)
    context.dispatch('updateCatalogProduct', { id: productId, payload: { ...product, tips: '' } })
  },
  async setMembershipImportInformation(context) {
    const cooperative = await cooperativeService.get(context.getters.cooperativeId)
    context.commit('SET_MEMBERSHIP_IMPORT_INFORMATION', cooperative)
  },
  async setShippingAddressesImportInformation(context) {
    const cooperative = await cooperativeService.get(context.getters.cooperativeId)
    context.commit('SET_SHIPPING_ADDRESSES_IMPORT_INFORMATION', cooperative)
  },
  async setStoresImportInformation(context) {
    const cooperative = await cooperativeService.get(context.getters.cooperativeId)
    context.commit('SET_STORES_IMPORT_INFORMATION', cooperative)
  },
  async setTechnicalSalesImportInformation(context) {
    const cooperative = await cooperativeService.get(context.getters.cooperativeId)
    context.commit('SET_TECHNICAL_SALES_IMPORT_INFORMATION', cooperative)
  },
  async setPartnerContactImportInformation(context) {
    const cooperative = await cooperativeService.get(context.getters.cooperativeId)
    context.commit('SET_PARTNER_CONTACT_IMPORT_INFORMATION', cooperative)
  },
  async setCustomProductImportInformation(context) {
    const importType = 'products'
    const { updated_at: lastCustomProductImportAt } = await importReportsService.getLastProductImport(
      context.getters.cooperativeId,
      importType,
    )
    const customProductCountResponse = await cooperativeCustomProductsV2Service.count(context.getters.cooperativeId)

    context.commit('SET_CUSTOM_PRODUCT_IMPORT_INFORMATION', {
      lastCustomProductImportAt,
      customProductCount: customProductCountResponse.count,
    })
  },
  async setIsTech(context) {
    const decodedToken = jwt.decode(
      JSON.parse(
        localStorage.getItem(`aladin.auth.user:${window.env.AUTH0_DOMAIN}:${window.env.AUTH0_CLIENT_ID}`) || '{}',
      ).access_token,
    )
    const roles = decodedToken['https://shop-api.marketplace-qua.invivodigitalfactory.com/v1/roles']
    if (roles) {
      const techRole = roles.find((role) => role.type === 'tech')
      context.commit('SET_IS_TECH', Boolean(techRole))
    }
  },
}

export const modules = {
  toasts,
  offers: getOfferStoreModule(),
  catalog,
  search,
  variantOffers: getOfferStoreModule(),
  stockQuantity,
  quota,
}

const store = new Vuex.Store({
  state: globalState,
  getters,
  mutations,
  actions,
  modules,
})

export const getStore = () => store

export default store

function findSellerPartner(user, role) {
  if (!role || !role.profiles[0]) {
    // Should never happen because access to front seller requires the user
    // to have permissions available only to a seller.
    throw new Error('The current user is missing seller role in Aladin access token')
  }
  const sellerProfile = role.profiles[0]
  return user.cooperatives?.filter((partner) => partner.id === sellerProfile.cooperative.id)?.[0]
}
