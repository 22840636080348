
import { defineComponent, Ref, ref } from 'vue'
import { ValidationObserver } from 'vee-validate-last'
import { IdfButton } from '@invivodf/ui-kit'
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import { DelayUnitOption } from '@invivodf/common/src/contexts/logistic-offer/domain/interfaces'
import CDeliveryModeChoice from '../CDeliveryModeChoice/CDeliveryModeChoice.vue'
import { useLogisticOfferRules, useLogisticOffer, useLogisticOfferWording } from '../../composables'
import { PInlineRadios, PLogisticDelay, PInput, PMandatory, PDeliveryNamePreview } from '../../presenters'
import { useLogisticRoutes } from '../../composables/useLogisticRoutes'

export default defineComponent({
  name: 'CLogisticOfferRouteStep',
  components: {
    CDeliveryModeChoice,
    IdfButton,
    PDeliveryNamePreview,
    PInput,
    PInlineRadios,
    PLogisticDelay,
    PMandatory,
    ValidationObserver,
  },
  emits: ['submit-step'],
  setup(props, { emit }) {
    const { logisticOffer, setLogisticOfferDeliveryMode } = useLogisticOffer()
    const { wording } = useLogisticOfferWording()
    const { logisticRoutes } = useLogisticRoutes()
    logisticRoutes.forEach((logisticRoute) => {
      logisticRoute.label = wording.editor.deliveryMode.route[logisticRoute.value]
    })
    const delayUnits: DelayUnitOption[] = [
      {
        value: 'hour',
        label: wording.editor.deliveryMode.delay.hour,
      },
      {
        value: 'day',
        label: wording.editor.deliveryMode.delay.day,
      },
      {
        value: 'working_day',
        label: wording.editor.deliveryMode.delay.working_day,
      },
      {
        value: 'week',
        label: wording.editor.deliveryMode.delay.week,
      },
      {
        value: 'month',
        label: wording.editor.deliveryMode.delay.month,
      },
    ]
    const isDrawerVisible: Ref<boolean> = ref(false)
    const onSubmit = (): void => {
      emit('submit-step')
    }
    const onDrawerClose = (): void => {
      isDrawerVisible.value = false
      document.body.classList.remove('drawer-open')
    }
    const onDrawerOpen = (): void => {
      isDrawerVisible.value = true
      document.body.classList.add('drawer-open')
    }
    const onSelectDeliveryMode = (deliveryMode: DeliveryModeEntity): void => {
      setLogisticOfferDeliveryMode(deliveryMode)
      onDrawerClose()
    }
    return {
      delayUnits,
      isDrawerVisible,
      logisticOffer,
      logisticRoutes,
      onDrawerClose,
      onDrawerOpen,
      onSelectDeliveryMode,
      onSubmit,
      rules: useLogisticOfferRules(),
      wording,
    }
  },
})
